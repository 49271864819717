// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
// import ListItem from './ListItem.jsx';

function List({ list }) {
  console.log({ list });
  // console.log({ three: true });
  // const mappedItems = Object.values(it.items);
  // console.log({ mappedItems });
  const l2 = list.map(
    (listItem) => {
      console.log({ listItem });
      const [k2, v2] = listItem.Name.value.split('=');
      console.log({ k2, v2 });
      // (<li key={listItem.id}>
      //   {listItem.Name.value}
      //   </li>);
      if (v2.startsWith('http')) {
        return (
          <div key={listItem.id}>
            <b>
              {k2}
              :
            </b>
            <Image
              key={listItem.id}
              width={200}
              height={200}
              scale={5.0}
              src={v2}
              alt='ALT TEXT'
              referrerPolicy='no-referrer'
            />
          </div>
        );
      }
      return (
        <li key={listItem.id}>
          <b>{k2}</b>
          :
          {' '}
          {v2}
        </li>
      );
      // return (<li key={listItem.id}>{listItem.Name.value}</li>);
    }
    ,
  );
  return l2;
}
/*
    val = (<ul>{list}</ul>);
  } else {
    const mappedItems = Object.values(dataListMap[variableToList]?.items);
    // dataListMap[variableToList].bag = mappedItems.map(
    //   (it) => it.Name.value,
    // );
    // dataListMap[variableToList].bag = mappedItems.map(
    //   (it) => it.Name.value,
    // );
    const list = mappedItems.map((it) => {
      console.log({ it });
      return (<li key={it.key}>{it.value}</li>);
    });
    val = (<ul>{list}</ul>);
  }
  val = (<ListItem key={i.id} item={i} />);
  return (
    <li>{item}</li>
  );
}
*/

List.propTypes = {
  list: PropTypes.array.isRequired,
};

export default List;
