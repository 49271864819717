// (C) Copyright 2020 MediaWink, LLC

import React, { useContext } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import GitInfo from 'react-git-info/macro';

import { Context } from '../components/Context';
import { timestamp } from '../constants';
import { basicSteps } from '../components/TourStepsBasic';

function Help() {
  const [context, setContext] = useContext(Context);
  const gitInfo = GitInfo();
  return (
    <Box>
      <Box
        align='center'
      >
        <h1>Help</h1>
      </Box>
      <h2>About</h2>
      <Typography>
        AchieveHub, Published:
        {' '}
        {timestamp}
      </Typography>
      <Typography className='git-info'>
        Code:
        {' '}
        {gitInfo.commit.date}
        ,
        {' '}
        {gitInfo.branch}
        ,
        {' '}
        {gitInfo.commit.hash}
        <br />
        If you know a newer version exists,
        you may need to
        <a href='https://www.pcmag.com/how-to/how-to-clear-your-cache-on-any-browser'>
          clear your cache
        </a>
        {' '}
        to get the latest.
      </Typography>
      <h2>If it breaks</h2>
      <Typography>
        <a href='achievehub.com'>Achievehub.com</a>
        {' '}
        is the main site to use.
        If it is not working, you can try
        <a href='old.chievehub.com'>old.achievehub.com</a>
        {' '}
        to use an older version that may not have the problems you may encounter.
        Also, if you want to try the latest version
        that has not been released to the general public
        yet, you can try
        <a href='latest.chievehub.com'>latest.achievehub.com</a>
        .  Be sure to bookmark these, or remember them,
        as you might not be able to access this page if you
        run into problems.
      </Typography>
      <h2>Getting Started</h2>
      <Box gap='small' pad='small'>
        <Typography>
          Click the button below to take a basic tour of AchieveHub.
          Note that the tour will only show what you have accessing
          to, so it is suggested to
          {' '}
          <b>sign in</b>
          {' '}
          before
          taking the tour.
        </Typography>
        <Button
          color='primary'
          onClick={() => setContext(
            {
              ...context, stepIndex: 0, run: true, steps: basicSteps, tour: 'basic',
            },
          )}
          variant='contained'
        >
          AchieveHub Basics Tour
        </Button>
      </Box>
      <h2>Kiosk</h2>
      You may want to use AchieveHub as a kiosk.  For example,
      you may want to show AchieveHub all the time so that
      children can mark off progress for home school or contributions/chores,
      with the computer unlocked,
      but not let them use anything else on the computer.
      If so, you can install and configure kiosk software
      on your computer.  iPhones and iPads can also be configured
      as a kiosk.  There are several options available.
      Below are the options we found helpful to others,
      and all options here are free.
      <h2>Kiosk on Computer</h2>
      OpenKiosk will work on Mac, Windows, or even Linux/raspberry pi, and is free!
      To set up your computer for use as a kiosk with AchieveHub, follow these steps:
      <ul>
        <li>
          Start by reading the short
          <a href='https://openkiosk.mozdevgroup.com/help.html'>
            OpenKiosk help page
          </a>
          {' '}
          before you begin.
        </li>
        <li>
          Visit
          {' '}
          <a href='https://openkiosk.mozdevgroup.com/'>OpenKiosk</a>
          {' '}
          and download OpenKiosk by pressing the Download Now button.
          If you wonder about the system requirements, you can find them here:
          <a href='https://openkiosk.mozdevgroup.com/system-requirements.html'>OpenKiosk System Requirements</a>
          .
        </li>
        <li>
          Open the downloaded package to install it with the default values.
          If you do not see an install button on Windowsy, select More Info,
          then select Run Anyway.
          For type of Custom or Install, they both do the same thing.
        </li>
        <li>
          Start OpenKiosk from the installed icon.
        </li>
        <li>
          Go to settings by pressing Shift+F1 (fn) keys
        </li>
        <li>
          Enter the password.  The default password is: admin
        </li>
        <li>
          In the
          {' '}
          <strong>OpenKiosk</strong>
          {' '}
          settings section
          <ul>
            <li>
              CHECK Enable Attract Screen and change it to: https://achievehub.com
              {/*
              (originally was https://openkiosk.mozdevgroup.com/attract.html)
              */}
            </li>
            <li>
              CHECK Enable Redirect Screen and change it to: https://achievehub.com
              {/*
              (originally was https://openkiosk.mozdevgroup.com/redirect.html)
              */}
            </li>
            <li>
              CHECK Enable Disk Cache
            </li>
            <li>
              CHECK Preserve Session History
            </li>
            <li>
              CHECK Preserve Session Cookies
            </li>
            <li>
              CHECK Preserve Session Cookies
            </li>
            <li>
              UNCHECK Enable Tabbed Browsing
            </li>
            <li>
              UNCHECK Enable URL Toolbar
            </li>
            <li>
              <strong>Password</strong>
              {' '}
              Enter a New Password and remember it !!
            </li>
            <li>
              CHECK Allow printing from web page
            </li>
            <li>
              CHECK Allow downloads dialog to be displayed
            </li>
            <li>
              CHECK Allow saving PDF files to downloads directory
              which will then be displayed to user
            </li>
            <li>
              CHECK Enable file upload form control on web pages
            </li>
          </ul>
        </li>
        <li>
          In the
          {' '}
          <strong>Home</strong>
          {' '}
          settings section
          <ul>
            <li>
              Set Homepage and new windows to: https://achievehub.com
              {/*
              (originally was https://openkiosk.mozdevgroup.com/)
              */}
            </li>
          </ul>
        </li>
      </ul>
      <h2>Kiosk on iPad/iPhone</h2>
      The iPad and iPhone come with a type of kiosk mode built in called
      <Link to='https://support.apple.com/en-us/HT202612'>Guided Access</Link>
      .
      <h2>Release Notes</h2>
      <h3>Releases</h3>
      <Typography> * 2023.01.17 - Only load items that need to be displayed, plus apps.</Typography>
      <Typography> * 2022.04.15 - Return to app list on app exit.</Typography>
      <Typography> * 2022.03.01 - Add kiosk instructions to help.</Typography>
      <Typography> * 2021.05.18 - Update icons</Typography>
      <Typography> * 2021.03.30 - Add ability to drag and drop to reorder items</Typography>
      <Typography> * 2021.03.27 - Add ability to rename fields</Typography>
      <Typography> * 2021.03.11 - Add ability to show paste multiple items setting</Typography>
      <Typography>
        {' '}
        * 2021.03.08 - Add automation setting
        (turn this off to avoid automatically creating new columns for new days, etc.)
      </Typography>
      <Typography> * 2021.03.04 - Add daily backups setting</Typography>
      <Typography> * 2020.11.30 - Fix dispensing</Typography>
      <Typography> * 2020.11.09 - Fix switching tree view issue</Typography>
      <Typography> * 2020.11.03 - Hide/show complete</Typography>
      <Typography> * 2020.11.02 - Basic and Homeschool tours</Typography>
      <Typography> * 2020.09.12 - Add owner specific IDs to database</Typography>
      <Typography> * 2020.08.27 - Save breadcrumbs to database</Typography>
      <Typography> * 2020.08.26 - Hide split screen when no item is selected</Typography>
      <Typography> * 2020.08.21 - Add Table View</Typography>
      <Typography> * 2020.07.10 - Add error dialog on login failure</Typography>
      <Typography> * 2020.07.08 - Add published timestamp to help page</Typography>
      {/*
      <Typography> * 2019.09.13 - Add edit right-side pane</Typography>
      */}
      <Typography> * 2019.08.31 - Add import and export of AchieveHub (.ah) files</Typography>
      <Typography> * 2019.08.29 - Add sub items</Typography>
      {/*
      <Typography> * 2019.08.17 - Add undo/redo</Typography>
      */}
      <Typography> * 2019.07.08 - Initial product</Typography>
      <Box basis='small' align='center'>
        <img alt='Wagon Wheel' src='android-chrome-192x192.png' />
      </Box>
    </Box>
  );
}

export default Help;
