// (C) Copyright 2020 MediaWink, LLC

/* eslint-disable no-shadow */

import { createSelector } from 'reselect';
import { selectAll, selectPrivateItems } from './items';

import { EMPTY_IMAGE } from '../constants';

// return All/Images
export const selectImages = createSelector(
  // state => state,
  selectAll,
  selectPrivateItems,
  (all, items) => {
    // console.log('all', all);
    // console.log('items', items);
    for (let i = 0; i < all.items.length; i += 1) {
      const images = items[all.items[i]];
      if (images?.Name?.value === 'Images') {
        // console.log('returning images', images);
        return images;
      }
    }
    // console.log('returning images NULL');
    return null;
  },
);

// Given a word, return url to that image
export const selectImage = (state, word) => createSelector(
  (state) => state,
  selectPrivateItems,
  selectImages,
  (_state, items, images) => {
    if (!images) {
      return EMPTY_IMAGE;
    }
    // console.log('images', images);
    // console.log('items', items);
    for (let j = 0; j < images.items.length; j += 1) {
      const image = items[images.items[j]];
      if (image?.Name?.value === word) {
        if (items[image.items[0]]?.Name?.value) {
          return items[image.items[0]]?.Name?.value;
        }
      }
    }
    // Default (White background image)
    return EMPTY_IMAGE;
  },
)(state);
