// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import Spinner from './spinner/spinner';
import {
  triggerCreateAccountSaga,
  triggerSetAllowlistSettingSaga,
} from '../actions/session';

function UserAdmin() {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.undo.present.private.settings);
  const setAllowlistTrigger = (payload, resolve, reject) => dispatch(
    triggerSetAllowlistSettingSaga(payload, resolve, reject),
  );
  const { promiseInProgress } = usePromiseTracker();
  const [message, setMessage] = useState('');
  const [accountEmail, setAccountEmail] = useState('');
  const [accountFirstName, setAccountFirstName] = useState('');
  const [accountFullName, setAccountFullName] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [allowlistText, setAllowlistText] = useState(settings?.allowlist);
  // const loginState = useSelector(state => state.undo.present.private.login);
  const createAccount = (payload, resolve, reject) => dispatch(
    triggerCreateAccountSaga(payload, resolve, reject),
  );
  const setAllowlist = (data) => {
    // console.warn('setAllowlist', data);
    console.log('new Promise: UserAdmin');
    trackPromise(
      new Promise((resolve, reject) => {
        setAllowlistTrigger(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Set backup daily failed.');
        }),
    );
  };
  const handleSubmitAllowlist = (event) => {
    event.preventDefault();
    setAllowlist([allowlistText]);
  };
  const handleSubmit = (event) => {
    // console.log('event', event);
    event.preventDefault();
    setMessage('');
    /*
    if (event.value.new_password !== event.value.new_password2) {
      setMessage('New passwords must match.');
      return;
    }
    */
    const data = {
      account_email: accountEmail,
      account_first_name: accountFirstName,
      account_full_name: accountFullName,
      account_password: accountPassword,
    };
    console.log('new Promise: UserAdmin');
    trackPromise(
      new Promise((resolve, reject) => {
        createAccount(data, resolve, reject);
        setMessage(`
Account created. Please send the following email:

### TO:
${accountFullName} &lt;${accountEmail}&gt;

### SUBJECT:
Welcome to AchieveHub!

### BODY:
Welcome to AchieveHub!
To log in:

* Go to: https://achievehub.com
* Click the Sign In icon (arrow pointing into a box on the top).
* (For gmail accounts) click the "Sign in with Google" button

For non-Google log-ins, you can enter the email and password below:

* Email: **${accountEmail}**
* Password (no spaces):

**${accountPassword}**

Thank you, and enjoy AchieveHub!

<a target="_blank" href="mailto:${accountEmail}?subject=Welcome to AchieveHub&body=Welcome to AchieveHub! %0D%0A To log in: %0D%0A * Go to: https://achievehub.com %0D%0A * Click the Sign In icon (arrow pointing into a box on the top). %0D%0A * (For gmail accounts) click the Sign in with Google button %0D%0A %0D%0A For non-Google log-ins, you can enter the email and password below: %0D%0A %0D%0A * Email: ${accountEmail} %0D%0A * Password (no spaces): %0D%0A %0D%0A ${accountPassword} %0D%0A %0D%0A Thank you, and enjoy AchieveHub!">Send as email</a>

`);
      })
        .catch(() => {
          // console.log(e);
          setMessage('Account creation failed.');
        }),
    );
  };
  return (
    <Box display='flex' flexDirection='column' p={2} m={2}>
      <h2>Allow List</h2>
      <Typography>
        Comma (and optional space) separated list of emails
        for people that can create their own account just by
        attempting to log in with their GMail accounts
      </Typography>
      <form onSubmit={handleSubmitAllowlist}>
        <Box>
          {/* settings.allowlist[0] */}
          <FormControlLabel
            control={
              (
                <TextField
                  multiline={true}
                  name='allowlist'
                  label='Allowlist'
                  // placeholder='Current password'
                  type='text'
                  value={allowlistText}
                  onChange={(event) => setAllowlistText(event.target.value)}
                />
              )
}
          />
        </Box>
        <Button
          color='primary'
          type='submit'
          variant='contained'
        >
          Update Allowlist
        </Button>
        {/*
        <FormControl fullWidth={true}>
          <InputLabel id='demo-simple-select-label'>Automatically add until</InputLabel>
          <Select
            // labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={settings.automation}
            label='Automatically add until'
            onChange={event => setAutomationOption(event.target.value)}
          >
            <MenuItem value={false}>Off</MenuItem>
            <MenuItem value='Today'>Today</MenuItem>
            <MenuItem value='Monday'>Monday</MenuItem>
            <MenuItem value='Tuesday'>Tuesday</MenuItem>
            <MenuItem value='Wednesday'>Wednesday</MenuItem>
            <MenuItem value='Thursday'>Thursday</MenuItem>
            <MenuItem value='Friday'>Friday</MenuItem>
            <MenuItem value='Saturday'>Saturday</MenuItem>
            <MenuItem value='Sunday'>Sunday</MenuItem>
          </Select>
        </FormControl>
        */}
      </form>
      <h2>Create Account</h2>
      <Typography>Create a new user account</Typography>
      {promiseInProgress && <Spinner text='Creating account...' />}
      <form onSubmit={handleSubmit}>
        <Box>
          <TextField
            name='accountEmail'
            label='New User Email'
            // placeholder='Current password'
            onChange={(e) => setAccountEmail(e.target.value)}
            type='text'
          />
        </Box>
        <Box>
          <TextField
            name='accountFullName'
            label='Full name'
            onChange={(e) => setAccountFullName(e.target.value)}
            type='text'
          />
        </Box>
        { /* TODO: Pre-populate first name */}
        <Box>
          <TextField
            name='accountFirstName'
            label='First name/nickname'
            onChange={(e) => setAccountFirstName(e.target.value)}
            type='text'
          />
        </Box>
        {/* default to Creator for now...
      <TextField
        name='role'
        label='Role'
        type='text'
      />
      */}
        { /* TODO: Generate button for password, or pre-populate */}
        <Box>
          <TextField
            name='accountPassword'
            label='Password'
            onChange={(e) => setAccountPassword(e.target.value)}
            type='text'
          />
        </Box>
        {/*
      <TextField
        name='new_password2'
        label='Re-enter New Password'
        type='password'
      />
      */}

        <Markdown>
          {message}
        </Markdown>
        <Box>
          <Button
            color='primary'
            type='submit'
            variant='contained'
          >
            Create Account
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default UserAdmin;
