// (C) Copyright 2020 MediaWink, LLC

import React, { useCallback, useMemo } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
} from '@material-ui/core';
import {
  setSelection,
  triggerDownItemSaga,
  triggerMoveItemSaga,
  triggerMovePageSaga,
  triggerRemoveItemSaga,
  triggerToggleItemSaga,
  triggerUpItemSaga,
  triggerUpdateItemSaga,
} from '../actions/items';
import Item from './Item';
import Spinner from './spinner/spinner';

function ItemList({
  debug,
  publicItems,
  privateItems,
  hideCheckmark,
  hideDelete,
  hideEdit,
}) {
  // const { promiseInProgress } = usePromiseTracker({ delay: 50 });
  const { promiseInProgress } = usePromiseTracker();
  const breadcrumbs = useSelector((state) => state.undo.present.private.breadcrumbs);
  const dispatch = useDispatch();
  const toggleItem = (data) => dispatch(triggerToggleItemSaga(data));
  const upItem = (data) => dispatch(triggerUpItemSaga(data));
  const downItem = (data) => dispatch(triggerDownItemSaga(data));
  const removeItem = (data) => dispatch(triggerRemoveItemSaga(data));
  const select = (breadcrumbsIn, id) => dispatch(setSelection(breadcrumbsIn, id));
  const updateItem = (data) => dispatch(triggerUpdateItemSaga(data));
  const sortedPrivateItems = useMemo(
    () => (privateItems ? Object.entries(privateItems).sort((a, b) => (
      (a[1].order > b[1].order) ? 1 : -1)) : {}),
    [privateItems],
  );

  const sortedPublicItems = publicItems ? Object.entries(publicItems).sort((a, b) => (
    (a[1].order > b[1].order) ? 1 : -1)) : {};
  const onDragEnd = useCallback((result) => {
    const moveItem = (data) => dispatch(triggerMoveItemSaga(data));
    const movePage = (data, resolve, reject) => dispatch(
      triggerMovePageSaga(data, resolve, reject),
    );
    if (result.combine) {
      const from = result.draggableId;
      const to = result.combine.draggableId;
      // console.log('new Promise: ItemList');
      trackPromise(
        new Promise((resolve, reject) => {
          movePage({ from, to }, resolve, reject);
        })
          .catch((e) => {
            console.log('Error', e);
          }),
      );
      return;
    }
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    if (sInd === dInd) {
      // console.log('===', sInd, dInd);
      // console.log('indexes', source.index, destination.index);
      const from = sortedPrivateItems[source.index][0];
      const to = sortedPrivateItems[destination.index][0];
      moveItem({ from, to });
    }
  }, [dispatch, sortedPrivateItems]);
  // console.log({ promiseInProgress });
  return (
    <Box
      className='item'
    >
      {promiseInProgress && <Spinner text='Getting data...' />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key='1' droppableId='1' isCombineEnabled={true}>
          {(dropProvided) => (
            <Box
              ref={dropProvided.innerRef}
            >
              {privateItems ? sortedPrivateItems.map(([key, value], ind) => (
                <Draggable
                  key={`${key}`}
                  draggableId={`${key}`}
                  index={ind}
                >
                  {(dragProvided) => (
                    <Box
                      ref={dragProvided.innerRef}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dragProvided.draggableProps}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dragProvided.dragHandleProps}
                    >
                      <Item
                        privateVisibility={true}
                        debug={debug}
                        item={privateItems[key]}
                        key={key}
                        name={value?.Name?.value || ''}
                        breadcrumbs={breadcrumbs}
                        toggleCompleteItem={() => toggleItem({ id: key })}
                        upItem={() => upItem({ id: key })}
                        downItem={() => downItem({ id: key })}
                        removeItem={() => removeItem({ id: key })}
                        id={value?.id}
                        childrenCount={'items' in value ? Object.keys(value?.items)?.length : 0}
                        setSelection={(crumbs, id) => select(crumbs, id)}
                        hideDelete={hideDelete}
                        hideEdit={hideEdit}
                        hideCheckmark={hideCheckmark}
                        updateItem={updateItem}
                      />
                    </Box>
                  )}
                </Draggable>
              )) : undefined}
              {publicItems ? sortedPublicItems.map(([key, value], ind) => (
                <Draggable
                  key={`pub-${key}`}
                  draggableId={`pub-${key}`}
                  index={ind + (sortedPrivateItems?.length || 0)}
                >
                  {(dragProvided) => (
                    <Box
                      ref={dragProvided.innerRef}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dragProvided.draggableProps}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dragProvided.dragHandleProps}
                    >
                      <Item
                        publicVisibility={true}
                        debug={debug}
                        item={publicItems[key]}
                        key={key}
                        name={value?.Name?.value || ''}
                        breadcrumbs={breadcrumbs}
                        toggleCompleteItem={() => toggleItem({ id: key })}
                        upItem={() => upItem({ id: key })}
                        downItem={() => downItem({ id: key })}
                        removeItem={() => removeItem({ id: key })}
                        id={value.id}
                        childrenCount={'items' in value ? Object.keys(value?.items)?.length : 0}
                        setSelection={(crumbs, id) => select(crumbs, id)}
                        hideDelete={hideDelete}
                        hideEdit={hideEdit}
                        hideCheckmark={hideCheckmark}
                        updateItem={updateItem}
                      />
                    </Box>
                  )}
                </Draggable>
              )) : undefined}
              {dropProvided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

ItemList.propTypes = {
  debug: PropTypes.bool.isRequired,
  hideCheckmark: PropTypes.bool,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  publicItems: PropTypes.object.isRequired,
  privateItems: PropTypes.object.isRequired,
};

ItemList.defaultProps = {
  hideCheckmark: false,
  hideDelete: false,
  hideEdit: false,
};

export default ItemList;
