// (C) Copyright 2020 MediaWink, LLC

import { put, select, takeLatest } from 'redux-saga/effects';

// import { selectPage } from '../selectors/items';

import {
  POP_PUBLIC_BREADCRUMB_SAGA,
  POP_PRIVATE_BREADCRUMB_SAGA,
  PUSH_BREADCRUMB_SAGA,
  PUSH_PUBLIC_BREADCRUMB_SAGA,
  PUSH_PRIVATE_BREADCRUMB_SAGA,
  SAVE_PRIVATE_BREADCRUMBS_SAGA,
  SAVE_PUBLIC_BREADCRUMBS_SAGA,
  SET_PUBLIC_BREADCRUMBS_SAGA,
  SET_PRIVATE_BREADCRUMBS_SAGA,
  SYNC_FROM_DATABASE_SAGA,
} from '../action-types';
import {
  popPublicBreadcrumb,
  popPrivateBreadcrumb,
  pushPrivateBreadcrumb,
  pushPublicBreadcrumb,
  setPublicBreadcrumbs,
  setPrivateBreadcrumbs,
} from '../actions/items';
import { savePrivateBreadcrumbsSaga, savePublicBreadcrumbsSaga } from './items';
import {
  selectPrivateBreadcrumbs,
  selectPrivateItems,
  selectPublicBreadcrumbs,
  selectPublicItems,
  selectLogin,
  selectParentPage,
  selectMissingItems,
} from '../selectors';

import { syncFromDatabaseSaga } from './session';

// action: breadcrumbs[], index (count of how many to pop)
export function* popPrivateBreadcrumbSaga(action) {
  // console.warn('popPrivateBreadcrumbSaga, action:', action);
  const { breadcrumbs, index } = action.data;
  try {
    yield put(popPrivateBreadcrumb(breadcrumbs.length - index - 1));
    const newCrumbs = yield select(selectPrivateBreadcrumbs);
    // console.log('newCrumbs', newCrumbs);
    yield savePrivateBreadcrumbsSaga({
      ...action,
      breadcrumbs: newCrumbs,
      type: SAVE_PRIVATE_BREADCRUMBS_SAGA,
    });
  } catch (e) {
    // console.log("error", e);
    /*
        if (reject) {
            reject();
        }
        */
  }
}

// action: breadcrumbs[], index (count of how many to pop)
export function* popPublicBreadcrumbSaga(action) {
  console.warn('popPublicBreadcrumbSaga, action:', action);
  const { breadcrumbs, index } = action.data;
  try {
    yield put(popPublicBreadcrumb(breadcrumbs.length - index - 1));
    const newCrumbs = yield select(selectPublicBreadcrumbs);
    // console.log('newCrumbs', newCrumbs);
    yield savePublicBreadcrumbsSaga({
      ...action,
      breadcrumbs: newCrumbs,
      type: SAVE_PUBLIC_BREADCRUMBS_SAGA,
    });
  } catch (e) {
    // console.log('error', e);
    /*
        if (reject) {
            reject();
        }
        */
  }
}

export function* pushPublicBreadcrumbSaga(action) {
  // console.log('pushPublicBreadcrumbSaga, action:', action);
  const { id } = action.data;
  try {
    // console.log('scrollTo 0, 0');
    window.scrollTo(0, 0);
    yield put(pushPublicBreadcrumb(id));
    const newCrumbs = yield select(selectPublicBreadcrumbs);
    // console.log('newCrumbs', newCrumbs);
    yield savePublicBreadcrumbsSaga({
      ...action,
      breadcrumbs: newCrumbs,
      type: SAVE_PUBLIC_BREADCRUMBS_SAGA,
    });
    // const page = useSelector(s => selectPage(s));
    // const page = yield select(selectPage);
    // console.log('page', page);
    // console.log('page?.MemorizationFiller', page?.MemorizationFiller);
  } catch (e) {
    // console.log("error", e);
    /*
        if (reject) {
            reject();
        }
        */
  }
}

export function* pushPrivateBreadcrumbSaga(action) {
  // console.log('pushPrivateBreadcrumbSaga, action:', action);
  const { id } = action.data;
  try {
    // console.log('scrollTo 0, 0');
    window.scrollTo(0, 0);
    yield put(pushPrivateBreadcrumb(id));
    const newCrumbs = yield select(selectPrivateBreadcrumbs);
    // console.log('newCrumbs', newCrumbs);
    yield savePrivateBreadcrumbsSaga({
      ...action,
      breadcrumbs: newCrumbs,
      type: SAVE_PRIVATE_BREADCRUMBS_SAGA,
    });
  } catch (e) {
    // console.log("error", e);
    /*
        if (reject) {
            reject();
        }
        */
  }
}

// Save pushPrivateBreadcrumbSaga
export function* pushBreadcrumbSaga(action) {
  // console.log('pushBreadcrumbSaga', action);
  const {
    id,
    name,
    privateVisibility,
    recursive,
    nav,
    navigate,
  } = action.data;

  const publicBreadcrumbs = yield select(selectPublicBreadcrumbs);
  const privateBreadcrumbs = yield select(selectPrivateBreadcrumbs);
  const privateItems = yield select(selectPrivateItems);
  const publicItems = yield select(selectPublicItems);
  // const privateVisibility = yield select(selectIsPrivate);
  // Find OTHER crumb id, from name...
  // console.log('Find id of other breadcrumbs from name', name);
  // console.log({ privateBreadcrumbs });
  // console.log({ publicBreadcrumbs });
  // console.log({ privateVisibility });
  let newPrivateId = -1;
  let newPublicId = -1;

  // console.log('yield terniary');
  yield (privateVisibility
    ? pushPrivateBreadcrumbSaga({
      ...action,
      data: {
        ...action.data,
        id,
      },
      id,
      type: PUSH_PRIVATE_BREADCRUMB_SAGA,
    })
    : pushPublicBreadcrumbSaga({
      ...action,
      data: {
        ...action.data,
        id,
      },
      id,
      type: PUSH_PUBLIC_BREADCRUMB_SAGA,
    }));

  if (privateVisibility) {
    // console.log('privateVisiblity');
    newPrivateId = id;
    // console.log({ newPrivateId });
    const otherTailId = publicBreadcrumbs.slice(-1)[0];
    // console.log('otherTailId', otherTailId);
    // get item for otherTail...
    // console.log('public items', publicItems);
    // traverse them until match is found (if there is one)
    const otherTail = publicItems[otherTailId];
    // console.log('otherTail', otherTail);
    let otherId = -1;
    otherTail.items.forEach((i) => {
      // console.log({ item: publicItems[i]?.Name?.value });
      if (publicItems[i]?.Name?.value === name) {
        // console.log('MATCH');
        // console.log({ otherId });
        // just once
        if (otherId < 0) {
          otherId = i;
          newPublicId = i;
          // console.log({ newPublicId });
        }
      }
    });
    if (otherId > -1) {
      // console.log('pushPublicBreadcrumbSaga otherId', otherId);
      yield pushPublicBreadcrumbSaga({
        ...action,
        data: {
          ...action.data,
          id: otherId,
        },
        type: PUSH_PUBLIC_BREADCRUMB_SAGA,
      });
    }
    // console.log({ otherId });
  } else {
    // console.log('publicVisiblity');
    newPublicId = id;
    // console.log({ newPublicId });
    const otherTailId = privateBreadcrumbs.slice(-1)[0];
    // console.log('otherTailId', otherTailId);
    // get item for otherTail...
    // console.log('private items', privateItems);
    // traverse them until match is found (if there is one)
    const otherTail = privateItems[otherTailId];
    // console.log('otherTail', otherTail);
    let otherId = -1;
    otherTail.items.forEach((i) => {
      // console.log({ item: publicItems[i]?.Name?.value });
      if (privateItems[i]?.Name?.value === name) {
        // console.log('MATCH');
        // just once
        // console.log({ otherId });
        if (otherId < 0) {
          otherId = i;
          newPrivateId = i;
          // console.log({ newPrivateId });
        }
      }
    });
    if (otherId > -1) {
      // console.log('pushPrivateBreadcrumbSaga otherId', otherId);
      yield pushPrivateBreadcrumbSaga({
        ...action,
        data: {
          ...action.data,
          id: otherId,
        },
        type: PUSH_PRIVATE_BREADCRUMB_SAGA,
      });
    }
    // console.log({ otherId });
  }
  // Check to see if we need to load missing items from DB...
  // console.log('SAGA selectMissingItems:');
  const missingItems = yield select(selectMissingItems);
  // console.log('SAGA missingItems.length', missingItems.length, missingItems);
  // console.log({ privateBreadcrumbs });
  // console.log({ publicBreadcrumbs });
  // console.log({ newPrivateId });
  // console.log({ newPublicId });
  // const page = yield select(selectPage);
  const parent = yield select(selectParentPage);
  // console.log({ parent });
  const login = yield select(selectLogin);
  if (missingItems.length > 0) {
    // console.log('TODO update DB');
    const obj = {
      ...action,
      data: login,
      item: 1,
      insert: true,
      recursive: parent?.Name?.value === 'App List' ?? recursive,
      privateItem: newPrivateId,
      publicItem: newPublicId,
      type: SYNC_FROM_DATABASE_SAGA,
    };
    // console.log({ obj });
    yield syncFromDatabaseSaga(obj);
  }
  if (nav) {
    navigate(nav);
  }
}

export function* setPrivateBreadcrumbsSaga(action) {
  // console.warn('setBreadcrumbsSaga, action:', action);
  const { breadcrumbs } = action.data;
  try {
    yield put(setPrivateBreadcrumbs(breadcrumbs));
    yield savePrivateBreadcrumbsSaga({
      ...action,
      breadcrumbs,
      type: SAVE_PRIVATE_BREADCRUMBS_SAGA,
    });
  } catch (e) {
    // console.log('error', e);
    /*
        if (reject) {
            reject();
        }
    */
  }
}

export function* setPublicBreadcrumbsSaga(action) {
  console.log('setPublicBreadcrumbsSaga, action:', action);
  const { breadcrumbs } = action.data;
  try {
    yield put(setPublicBreadcrumbs(breadcrumbs));
    yield savePublicBreadcrumbsSaga({
      ...action,
      breadcrumbs,
      type: SAVE_PUBLIC_BREADCRUMBS_SAGA,
    });
  } catch (e) {
    console.log('error', e);
    /*
        if (reject) {
            reject();
        }
    */
  }
}

export function* watchBreadcrumbSagas() {
  yield takeLatest(POP_PUBLIC_BREADCRUMB_SAGA, popPublicBreadcrumbSaga);
  yield takeLatest(POP_PRIVATE_BREADCRUMB_SAGA, popPrivateBreadcrumbSaga);
  yield takeLatest(PUSH_BREADCRUMB_SAGA, pushBreadcrumbSaga);
  yield takeLatest(PUSH_PUBLIC_BREADCRUMB_SAGA, pushPublicBreadcrumbSaga);
  yield takeLatest(PUSH_PRIVATE_BREADCRUMB_SAGA, pushPrivateBreadcrumbSaga);
  yield takeLatest(SET_PUBLIC_BREADCRUMBS_SAGA, setPublicBreadcrumbsSaga);
  yield takeLatest(SET_PRIVATE_BREADCRUMBS_SAGA, setPrivateBreadcrumbsSaga);
}
