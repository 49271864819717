// (C) Copyright 2020 MediaWink, LLC

export const ADD_CHILD = 'ADD_CHILD';
export const ADD_CHILD_SAGA = 'ADD_CHILD_SAGA';
export const ADD_DIRTY = 'ADD_DIRTY';
export const ADD_FIELD_PRIVATE = 'ADD_FIELD_PRIVATE';
export const ADD_FIELD_SAGA = 'ADD_FIELD_SAGA';
export const ADD_ITEM_PUBLIC = 'ADD_ITEM_PUBLIC';
export const ADD_ITEM_PRIVATE = 'ADD_ITEM_PRIVATE';
export const ADD_ITEM_SAGA = 'ADD_ITEM_SAGA';
export const ADD_ITEM_TO_BOTTOM_SAGA = 'ADD_ITEM_TO_BOTTOM_SAGA';
export const ADD_ITEM_TO_TOP_SAGA = 'ADD_ITEM_TO_TOP_SAGA';
export const ADJUST = 'ADJUST';
export const CLEAR_DIRTY_BIT_FOR_ITEM = 'CLEAR_DIRTY_BIT_FOR_ITEM';
export const DELETE_DIRTY = 'DELETE_DIRTY';
export const DELETE_FIELD_PRIVATE = 'DELETE_FIELD_PRIVATE';
export const DELETE_FIELD_SAGA = 'DELETE_FIELD_SAGA';
export const DOWN_ITEM = 'DOWN_ITEM';
export const DOWN_ITEM_SAGA = 'DOWN_ITEM_SAGA';
export const EDIT_ITEM = 'EDIT_ITEM';
export const GET_IMAGE_SAGA = 'GET_IMAGE_SAGA';
export const PLAY_APP_SAGA = 'PLAY_APP_SAGA';
export const POP_PRIVATE_BREADCRUMB = 'POP_PRIVATE_BREADCRUMB';
export const POP_PUBLIC_BREADCRUMB = 'POP_PUBLIC_BREADCRUMB';
export const POP_PUBLIC_BREADCRUMB_SAGA = 'POP_PUBLIC_BREADCRUMB_SAGA';
export const POP_PRIVATE_BREADCRUMB_SAGA = 'POP_PRIVATE_BREADCRUMB_SAGA';
export const POP_RESULT = 'POP_RESULT';
export const PUSH_PRIVATE_BREADCRUMB = 'PUSH_PRIVATE_BREADCRUMB';
export const PUSH_PUBLIC_BREADCRUMB = 'PUSH_PUBLIC_BREADCRUMB';
export const PUSH_PUBLIC_BREADCRUMB_SAGA = 'PUSH_PUBLIC_BREADCRUMB_SAGA';
export const PUSH_PRIVATE_BREADCRUMB_SAGA = 'PUSH_PRIVATE_BREADCRUMB_SAGA';
export const PUSH_BREADCRUMB_SAGA = 'PUSH_BREADCRUMB_SAGA';
export const SET_BREADCRUMB_SAGA = 'SSET_BREADCRUMB_SAGA';
export const PUSH_DIRTY = 'PUSH_DIRTY';
export const PUSH_RESULT = 'PUSH_RESULT';
export const PUT_AND_UPDATE_SAGA = 'PUT_AND_UPDATE_SAGA';
export const MOVE_ITEM_PRIVATE = 'MOVE_ITEM_PRIVATE';
export const MOVE_ITEM_SAGA = 'MOVE_ITEM_SAGA';
export const MOVE_PAGE_PRIVATE = 'MOVE_PAGE_PRIVATE';
export const MOVE_PAGE_SAGA = 'MOVE_PAGE_SAGA';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEM_PRIVATE = 'REMOVE_ITEM_PRIVATE';
export const REMOVE_ITEM_SAGA = 'REMOVE_ITEM_SAGA';
export const RENAME_ITEM = 'RENAME_ITEM';
export const SAVE_PRIVATE_BREADCRUMBS_SAGA = 'SAVE_PRIVATE_BREADCRUMBS_SAGA';
export const SAVE_PUBLIC_BREADCRUMBS_SAGA = 'SAVE_PUBLIC_BREADCRUMBS_SAGA';
export const SET_AND_SAVE_VARIABLE_SAGA = 'SET_AND_SAVE_VARIABLE_SAGA';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD';
export const SET_COLUMNS_PER_PAGE = 'SET_COLUMNS_PER_PAGE';
export const SET_COLUMNS_PER_PAGE_SAGA = 'SET_COLUMNS_PER_PAGE_SAGA';
export const SET_FIRST_VISIBLE_COLUMN = 'SET_FIRST_VISIBLE_COLUMN';
export const SET_FIRST_VISIBLE_COLUMN_SAGA = 'SET_FIRST_VISIBLE_COLUMN_SAGA';
export const SET_HTML = 'SET_HTML';
export const SET_SHOW_VIEW_SETTINGS = 'SET_SHOW_VIEW_SETTINGS';
export const SET_SHOW_VIEW_SETTINGS_SAGA = 'SET_SHOW_VIEW_SETTINGS_SAGA';
export const SET_PRIVATE_BREADCRUMBS_SAGA = 'SET_PRIVATE_BREADCRUMBS_SAGA';
export const SET_PUBLIC_BREADCRUMBS_SAGA = 'SET_PUBLIC_BREADCRUMBS_SAGA';
export const SET_PRIVATE_BREADCRUMBS = 'SET_PRIVATE_BREADCRUMBS';
export const SET_PUBLIC_BREADCRUMBS = 'SET_PUBLIC_BREADCRUMBS';
export const SET_DIRTY = 'SET_DIRTY';
export const SET_NEXT_ID = 'SET_NEXT_ID';
export const SET_HIDE_ADD_BOTTOM = 'SET_HIDE_ADD_BOTTOM';
export const SET_HIDE_ADD_TOP = 'SET_HIDE_ADD_TOP';
export const SET_HIDE_ADD_BOTTOM_SAGA = 'SET_HIDE_ADD_BOTTOM_SAGA';
export const SET_HIDE_ADD_TOP_SAGA = 'SET_HIDE_ADD_TOP_SAGA';
export const SET_HIDE_DELETE = 'SET_HIDE_DELETE';
export const SET_HIDE_FIELDS = 'SET_HIDE_FIELDS';
export const SET_HIDE_EDIT = 'SET_HIDE_EDIT';
export const SET_HIDE_CHECKMARK = 'SET_HIDE_CHECKMARK';
export const SET_HIDE_CRUMBS_BODY = 'SET_HIDE_CRUMBS_BODY';
export const SET_HIDE_CRUMBS_TAIL = 'SET_HIDE_CRUMBS_TAIL';
export const SET_HIDE_CHILDREN = 'SET_HIDE_CHILDREN';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_TABLE_VIEW = 'SET_TABLE_VIEW';
export const SET_TABLE_VIEW_GROUPS = 'SET_TABLE_VIEW_GROUPS';
export const SET_TABLE_VIEW_GROUPS_SAGA = 'SET_TABLE_VIEW_GROUPS_SAGA';
export const SET_TABLE_VIEW_SAGA = 'SET_TABLE_VIEW_SAGA';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const SHOW_ACTIVE = 'SHOW_ACTIVE';
export const SHOW_ALL = 'SHOW_ALL';
export const SHOW_COMPLETED = 'SHOW_COMPLETED';
export const SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA = 'SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA';
export const SYNC_ITEMS_TO_DATABASE_SAGA = 'SYNC_ITEMS_TO_DATABASE_SAGA';
export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const TOGGLE_ITEM_PRIVATE = 'TOGGLE_ITEM_PRIVATE';
export const TOGGLE_ITEM_SAGA = 'TOGGLE_ITEM_SAGA';
export const UP_ITEM = 'UP_ITEM';
export const UP_ITEM_SAGA = 'UP_ITEM_SAGA';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_SAGA = 'UPDATE_ITEM_SAGA';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const UPDATE_FIELD_SAGA = 'UPDATE_FIELD_SAGA';
export const UPDATE_ITEM_BY_ID_SAGA = 'UPDATE_ITEM_BY_ID_SAGA';
export const UPDATE_ITEM_ID = 'UPDATE_ITEM_ID';
