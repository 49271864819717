// (C) Copyright 2020 MediaWink, LLC

// import React from 'react';
// import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
  Login,
  Logout,
  Task,
  UserAdmin,
  UserSettings,
} from 'grommet-icons';

import {
  AccountTree,
  Add,
  Build,
  CheckBox,
  CloudDownload,
  GridOn,
  ArrowDownward,
  ArrowUpward,
  BugReport,
  Check,
  Close,
  Delete,
  Edit,
  InsertDriveFile,
  FastForward,
  FastRewind,
  FolderShared,
  FormatListBulleted,
  Help,
  Home,
  List,
  Lock,
  MoreHoriz,
  NavigateNext,
  NavigateBefore,
  Pause,
  PlayArrow,
  Public,
  Redo,
  SettingsEthernet,
  SkipNext,
  SkipPrevious,
  Stop,
  Storage,
  TableChart,
  Undo,
  Menu,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import GavelIcon from '@mui/icons-material/Gavel';
import PolicyIcon from '@mui/icons-material/Policy';
import QuizIcon from '@mui/icons-material/Quiz';
import SyncIcon from '@mui/icons-material/Sync';

// import List from '@material-ui/icons';
export const ListIcon = List;

// https://mui.com/components/material-icons/

export {
  AccountTree as TreeIcon,
  Add as AddIcon,
  ArrowDownward as DownIcon,
  ArrowUpward as UpIcon,
  BugReport as DebugIcon,
  Build as BuildIcon,
  Check as CheckmarkIcon,
  CheckBox as CheckboxIcon,
  Close as CloseIcon,
  CloudDownload as DownloadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GavelIcon as TermsIcon,
  GridOn as GroupIcon,
  Home as HomeIcon,
  InsertDriveFile as FileIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  MoreHoriz as CrumbsBodyIcon,
  NavigateNext as NextColumnIcon,
  NavigateBefore as PreviousColumnIcon,
  Public as PublicIcon,
  Lock as PrivateIcon,
  FolderShared as SharedIcon,
  SettingsEthernet as CrumbsTailIcon,
  SkipNext as LastColumnIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as FirstColumnIcon,
  SkipPrevious as SkipPreviousIcon,
  Storage as DatabaseIcon,
  Stop as StopIcon,
  Task as TaskIcon,
  FastForward as NextPageIcon,
  FastRewind as PreviousPageIcon,
  Visibility as OpenEyeIcon,
  VisibilityOff as ClosedEyeIcon,
  Help as HelpIcon,
  Menu as MenuIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  PolicyIcon,
  Redo as RedoIcon,
  SyncIcon,
  QuizIcon,
  TableChart as TableIcon,
  Undo as UndoIcon,
  FormatListBulleted as UnorderedListIcon,
  UserAdmin as UserAdminIcon,
  UserSettings as UserSettingsIcon,
};

// const createMaterialIcon = name => () => <MaterialCommunityIcons name={name} size={30} />
// export const FieldsIcon = createMaterialIcon('format-columns');
