// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
} from '@material-ui/core';
import { OpenEyeIcon, ClosedEyeIcon } from '../constants/icons';
import * as COLORS from '../constants/colors';
import { triggerSetShowViewSettingsSaga } from '../actions/items';
import { selectPage } from '../selectors/items';

function ViewSettingsEye() {
  const page = useSelector((state) => selectPage(state));
  const dispatch = useDispatch();
  const setShowViewSettings = (data) => dispatch(triggerSetShowViewSettingsSaga(data));
  return (!page?.showViewSettings ? (
    <OpenEyeIcon
      className='tour-eye'
      style={{ fill: COLORS.GRAY40 }}
      onClick={() => setShowViewSettings({
        id: page.id,
        showViewSettings: !page.showViewSettings || false,
      })}
    />
  ) : (
    <Box
      bgcolor='#e6e6e6'
      className='tour-closed-eye'
      pb={2}
    >
      <ClosedEyeIcon
        className='tour-eye'
        style={{ fill: COLORS.GRAY40 }}
        onClick={() => setShowViewSettings({
          id: page.id,
          showViewSettings: !page.showViewSettings || false,
        })}
      />
    </Box>
  ));
}

export default ViewSettingsEye;
