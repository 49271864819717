// (C) Copyright 2020 MediaWink, LLC

/* eslint-disable no-shadow */

import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';
import { DEFAULT_FIELDS } from '../constants';

const ALL = 1;
// export const selectState = (state) => state;
// export const setSelection = id => { [id] } = state.undo.present.private.entities.items;
export const selectPresent = (state) => state.undo.present.private;
export const selectPrivateBreadcrumbs = (state) => state.undo.present.private.breadcrumbs || [1];
export const selectPublicBreadcrumbs = (state) => state.undo.present.public.breadcrumbs || [1];
export const selectPrivateItems = (state) => state.undo.present.private.entities.items;
export const selectPublicItems = (state) => state.undo.present.public.entities.items;
export const selectNextId = (state) => state.undo.present.private.nextId;
export const selectSelectedId = (state) => state.undo.present.private.selectedId;
export const selectSelectedField = (state) => state.undo.present.private.selectedField;
export const selectSettings = (state) => state.undo.present.private.settings;
export const selectVisibilityFilter = (state) => state.undo.present.private.visibilityFilter;

/*
export const selectBreadcrumbTail = createSelector(
    selectItems,
    selectPrivateBreadcrumbs,
    (items, crumbs) => {
        if(!items || !items[crumbs.slice(-1)]) {
            return [];
        }
        return items[crumbs.slice(-1)]['items'];
    }
);
*/

// What needs to be loaded from the database
export const selectMissingPrivateItems = createSelector(
  selectPrivateItems,
  (state) => state,
  (items, state) => {
    if (!items || !items[state.undo.present.private.breadcrumbs.slice(-1)]) {
      return [];
    }
    const rVal = items[state.undo.present.private.breadcrumbs.slice(-1)].items
      .reduce((acc, cur) => {
        if (state.undo.present.private.entities.items[cur] === undefined) {
          // console.log('cur missing (private)', cur);
          acc.push(cur);
        }
        return acc;
      }, []);
    // console.log('missingPrivateItems', rVal);
    return rVal;
  },
);

// What needs to be loaded from the database
export const selectMissingPublicItems = createSelector(
  selectPublicItems,
  (state) => state,
  (items, state) => {
    if (!items || !items[state.undo.present.public.breadcrumbs.slice(-1)]) {
      return [];
    }
    const rVal = items[state.undo.present.public.breadcrumbs.slice(-1)].items
      .reduce((acc, cur) => {
        if (state.undo.present.public.entities.items[cur] === undefined) {
          // console.log('cur missing (public)', cur);
          acc.push(cur);
        }
        return acc;
      }, []);
    // console.log('missingPublicItems', rVal);
    return rVal;
  },
);

// What needs to be loaded from the database
export const selectMissingItems = createSelector(
  selectMissingPrivateItems,
  selectMissingPublicItems,
  (privateItems, publicItems) => [...privateItems, ...publicItems],
);

// Given ID return crumbs
export const selectPrivateBreadcrumbsById = (state, id) => createSelector(
  (state) => state,
  selectPrivateItems,
  (state, items) => {
    // console.log('selectPrivateBreadcrumbsById id:', id);
    // console.log(' items[id]:', items[id]);
    let crumbs = [];
    let i = id;
    let max = 1000;
    while (i > ALL && max > 0) {
      crumbs = [i, ...crumbs];
      // get parent
      i = items[i].parent;
      max -= 1;
    }
    return [ALL, ...crumbs];
  },
)(state);

// Given ID return item object
export const selectPrivateItemById = (state, id) => createSelector(
  (state) => state,
  selectPrivateItems,
  (state, items) => items[id],
)(state);

export const selectPublicItemById = (state, id) => createSelector(
  (state) => state,
  selectPublicItems,
  (state, items) => items[id],
)(state);

// Given array of IDs return array of item objects
export const selectItemsByIds = (state, ids) => createSelector(
  (state) => state,
  selectPrivateItems,
  (state, items) => (ids ? ids.map((id) => items[id]) : []),
)(state);

export const selectSelectedItem = createSelector(
  selectPrivateItems,
  selectSelectedId,
  (items, id) => items[id],
);

export const selectIsPrivate = createSelector(
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  (privateBreadcrumbs, publicBreadcrumbs) => privateBreadcrumbs.length > publicBreadcrumbs.length,
);

export const selectBreadcrumbs = createSelector(
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectIsPrivate,
  (privateBreadcrumbs, publicBreadcrumbs, isPrivate) => (isPrivate
    ? privateBreadcrumbs : publicBreadcrumbs),
);

export const selectItems = createSelector(
  selectPrivateItems,
  selectPublicItems,
  selectIsPrivate,
  (privateItems, publicItems, isPrivate) => (isPrivate
    ? privateItems : publicItems),
);

export const selectItemsForBreadcrumbs = createSelector(
  selectPublicItems,
  selectPrivateItems,
  selectBreadcrumbs,
  selectIsPrivate,
  (publicItems, privateItems, breadcrumbs, isPrivate) => {
    const items = isPrivate ? privateItems : publicItems;
    const crumbs = breadcrumbs.map(
      (i) => items[i],
    );
    return crumbs;
  },
);

export const selectPageIdArray = createSelector(
  selectBreadcrumbs,
  (crumbs) => crumbs.slice(-1),
);

export const selectParentPageIdArray = createSelector(
  selectBreadcrumbs,
  (crumbs) => crumbs.slice(-2, 2),
);

export const selectPublicPageIdArray = createSelector(
  selectPublicBreadcrumbs,
  (crumbs) => crumbs.slice(-1),
);

export const selectPrivatePageIdArray = createSelector(
  selectPrivateBreadcrumbs,
  (crumbs) => crumbs.slice(-1),
);

export const selectGrandParentPageIdArray = createSelector(
  selectPrivateBreadcrumbs,
  (crumbs) => [crumbs.slice(-3)[0]],
);

export const selectPageId = createSelector(
  selectPageIdArray,
  (idArray) => idArray[0],
);

export const selectParentPageId = createSelector(
  selectParentPageIdArray,
  (idArray) => idArray[0],
);

export const selectGrandParentPageId = createSelector(
  selectGrandParentPageIdArray,
  (idArray) => idArray[0],
);

export const selectGrandParentPage = createSelector(
  selectPublicItems,
  selectGrandParentPageId,
  (items, id) => items[id] || items[ALL], // Default to All
);

export const selectPage = createSelector(
  selectItems,
  selectPageId,
  (items, id) => items[id] || items[ALL], // Default to All
);

export const selectParentPage = createSelector(
  selectItems,
  selectParentPageId,
  (items, id) => items[id] || items[ALL], // Default to All
);

/*
export const selectPageByPath = createSelector(
  selectItems,
  selectPage,
  (items, page) => items[id] || items[ALL], // Default to All
);
*/
/*
// Given path return item object (eg /App List/)
export const selectPrivateItemById = (state, id) => createSelector(
  state => state,
  selectItems,
  (state, items) => items[id],
)(state);
*/

// Get current App page (eg /App List/Kin Quiz/)
export const selectCurrentApp = createSelector(
  selectPublicItems,
  selectPublicBreadcrumbs,
  (items, crumbs) => items[crumbs.slice(2, 3)[0]],
);

export const selectDataList = createSelector(
  selectItems,
  selectCurrentApp,
  (items, app) => {
    if (app === 'NEVER') {
      console.log({ app });
    }
    return app?.items?.map((i) => items[i])?.filter(
      (i) => i?.Name?.value === 'Data List',
    )['0']?.items?.map((i) => items[i]);
  },
);

export const selectDataListMap = createSelector(
  selectItems,
  selectDataList,
  (items, list) => {
    // console.log({ 'selectDataListMap': 'called', items, list });
    const reduce = (obj, item) => {
      if (obj === 'NEVER') {
        console.log({ reduce: true, obj, item });
      }
      if (!Array.isArray(item?.items)) {
        console.error(
          'ERROR: {} detected instead of [] in item.items!!  Setting {} to [].',
          { item },
        );
        item.items = [];
      }
      return ({
        ...obj,
        [item?.Name?.value?.split('=')[0]]: {
          ...item,
          // itemsNonRecursive: item?.items?.map((i) => items[i]), // ?.reduce(reduce, {}),
          // TODO: BudgetHub
          // items: Array.isArray(item?.items) ? item?.items?.map((i) => {
          items: item?.items?.map((i) => {
            // console.log({ i, itemsI: items[i] });
            const out = {
              ...items[i],
              items: items[i].items.map((id) => items[id]).reduce(reduce, {}),
            };
            // console.log({ out });
            return out;
          // TODO: BudgetHub
          // }).reduce(
          //   (obj, item) => ({
          //     ...obj,
          //     [item.Name.value]: item,
          }),
          // TODO: BudgetHub
          //   {},
          // ) // [] -> {}
          // : item.items,
          /*
          items: item?.items?.map((i) => {
            const it = items[i];
            console.log({ it });
            // ?.reduce(reduce, {});
            return {
              ...it,
            };
          }),
          */
          // TODO: BudgetHub
          // value: item?.Name?.value?.split('=')?.slice(1)?.join('='),
          value: item?.Name?.value, // ?.split('=')?.slice(1)?.join('='),
        },
      });
    };
    const dataListMap = list?.reduce(reduce, {});
    // console.log({ dataListMap, returned: true });
    return dataListMap;
  },
);

export const selectPageItemNames = createSelector(
  selectPage,
  selectPublicItems,
  (page, items) => (page.items ? page.items.map((id) => items[id]?.Name?.value) : []),
);

export const selectTableView = createSelector(
  selectPage,
  (page) => page.tableView,
);

export const selectItemsInGraphArray = createSelector(
  selectPublicItems,
  selectPageIdArray,
  (items, idArray) => items[idArray]?.items,
);

export const selectLastColumnIdOnCurrentPage = createSelector(
  selectItemsInGraphArray,
  (currentItems) => currentItems?.slice(-1),
);

export const selectFirstColumnIdOnCurrentPage = createSelector(
  selectItemsInGraphArray,
  (currentItems) => currentItems[0],
);

export const selectFirstColumnOnCurrentPage = createSelector(
  selectPrivateItems,
  selectFirstColumnIdOnCurrentPage,
  (items, id) => items[id],
);

export const selectLastColumnOnCurrentPage = createSelector(
  selectPublicItems,
  selectLastColumnIdOnCurrentPage,
  (items, id) => items[id],
);

export const selectDispensersSuggestions = createSelector(
  (state) => state,
  selectPrivateItems,
  selectPage,
  selectPageItemNames,
  (state, items, item, pageItemNames) => {
    // console.log('>>>>> selectDispensersSuggestions');
    const out = [];
    try {
      // console.log('item', item);
      const parent = item;// items[item.parent];
      // console.log('parent', parent);
      const grandparent = items[parent.parent];
      // console.log('grandparent', grandparent);
      if (!grandparent) {
        return [];
      }
      const dispensersTarget = grandparent.Dispensers.value;
      // console.log('dispensersTarget', dispensersTarget);
      const greatgrandparent = items[grandparent.parent];
      // console.log('greatgrandparent', greatgrandparent);
      // Find dispenser
      for (let ggIndex = 0; ggIndex < greatgrandparent.items.length; ggIndex += 1) {
        const dispensersId = greatgrandparent.items[ggIndex];
        const dispensers = items[dispensersId];
        // console.log('dispensers', dispensers);
        if (dispensers && dispensers.Name && dispensers.Name.value === dispensersTarget) {
          const dispensersLength = dispensers.items.length;
          // console.log('Dispensers FOUND!', dispensersLength, dispensers.Name.value);
          for (let dispensersIndex = 0; dispensersIndex < dispensersLength; dispensersIndex += 1) {
            const dispensersId = dispensers.items[dispensersIndex];
            const dispenser = items[dispensersId];
            // console.log('dispenser', dispenser);
            // console.log('out', out);
            if (dispenser && dispenser.Name && dispenser.Name.value) {
              const name = dispenser.Name.value;
              if (!pageItemNames.includes(name)) {
                out.push(name);
              }
            }
          }
        }
      }
    } catch (e) {
      // console.warn('e', e);
    }
    // console.log('out', out);
    return out;
  },
);

export const selectDispenseForId = (state, id, title) => createSelector(
  (state) => state,
  selectPrivateItems,
  (state, items) => {
    // console.log('>> selectDispenseForId >> id', id);
    let item = items[id];
    try {
      // console.log('item', item);
      let parent = items[item.parent];
      // console.log('parent', parent);
      const grandparent = items[parent.parent];
      // console.log('grandparent', grandparent);
      const dispensersTarget = grandparent.Dispensers.value;
      // console.log('dispensersTarget', dispensersTarget);
      const greatgrandparent = items[grandparent.parent];
      // console.log('greatgrandparent', greatgrandparent);
      const groupName = items[item.group].Name.value;
      // console.log('groupName', groupName);
      // Find dispenser
      for (let ggIndex = 0; ggIndex < greatgrandparent.items.length; ggIndex += 1) {
        const dispensersId = greatgrandparent.items[ggIndex];
        const dispensers = items[dispensersId];
        // console.log('dispensers', dispensers);
        if (dispensers.Name.value === dispensersTarget) {
          // console.log('Dispensers FOUND!', dispensers.Name.value);
          const dispensersLength = dispensers.items.length;
          for (let dispensersIndex = 0; dispensersIndex < dispensersLength; dispensersIndex += 1) {
            const dispensersId = dispensers.items[dispensersIndex];
            const dispenser = items[dispensersId];
            // console.log('*** dispenser.Name.value', dispenser.Name.value);
            // console.log('*** groupName', groupName);
            if (dispenser.Name.value === groupName) {
              if (dispenser.Exception && title.includes(dispenser['Exception Pattern'].value)) {
                // console.log('return dispenser.Exception.value', dispenser.Exception.value);
                return dispenser.Exception.value;
              }
              if (!item.completed) {
                // console.log('not completed--try again', item.Name.value);
                return item.Name.value;
              }
              // console.log('EXCEPTION === item????', item.Name.value);
              if (dispenser.Exception) {
                let sibblingId = -1;
                while (item.Name.value === dispenser.Exception.value) {
                  // console.log('while ===', item.Name.value, dispenser.Exception.value);
                  sibblingId = -1;
                  // console.log('EXCPETION. item.group', item.group);
                  // console.log('grandparent', grandparent);
                  parent = items[item.parent];
                  const parentIndex = grandparent.items.indexOf(parent.id);
                  if (parentIndex > 0) {
                    const sibbling = items[grandparent.items[parentIndex - 1]];
                    // console.log('sibbling', sibbling);
                    // eslint-disable-next-line no-loop-func
                    sibbling.items.forEach((i) => {
                      // console.log('child', i, items[i]);
                      if (item.group === items[i].group) {
                        // console.log('match!');
                        sibblingId = i;
                      }
                    });
                  }
                  if (sibblingId > 0) {
                    item = items[sibblingId];
                  }
                }
                if (sibblingId > 0) {
                  // console.log('item', item);
                  // item = items[sibblingId];
                  // parent = items[item.parent];
                  // grandparent = items[parent.parent];
                  // console.log('return selectDispenseForId', sibblingId, title);
                  return selectDispenseForId(state, sibblingId, title);
                }
              }
              if (!item.completed) {
                // console.log('not completed--try again', item.Name.value);
                return item.Name.value;
              }
              // console.log('FOUND dispenser', groupName);
              let dispenseNext = false;
              // dispenser.items.forEach((dispenseId) => {
              const dispenserLength = dispensers.items.length;
              for (let dispenserIndex = 0; dispenserIndex < dispenserLength; dispenserIndex += 1) {
                const dispenseId = dispenser.items[dispenserIndex];
                const dispense = items[dispenseId];
                // console.log('dispense', dispense.Name.value);
                if (dispenseNext) {
                  // console.log('return', dispense.Name.value);
                  return dispense.Name.value;
                }
                if (dispense.Name.value === item.Name.value) {
                  // console.log('dispenseNext = true');
                  // Dispense Next?
                  // TODO: Exceptions, like Friday Spelling Tests,
                  //  or, count # of Spelling Tests done, etc.
                  dispenseNext = true;
                }
                // return false;
              }
            }
          }
        }
      }
      // console.log('default item (return item.Name.value)', item);
      return item.Name.value;
    } catch (e) {
      // console.log('e', e);
    }
    return item.Name.value;
  },
)(state);

export const selectPageItemsArray = createSelector(
  selectPage,
  selectPrivateItems,
  (page, items) => page.items.map((i) => items[i]),
);

export const selectGrandParentMenuListItemsArray = createSelector(
  selectGrandParentPage,
  selectItems,
  (page, allItems) => page
    .items
    .map((i) => allItems[i])
    ?.filter((i) => i?.Name?.value === 'Menu List')['0']
    ?.items
    ?.map((j) => allItems[j]),
);

// Select the current app
export const selectAppId = createSelector(
  selectBreadcrumbs,
  selectItems,
  (crumbs) => crumbs.slice(2, 3)[0],
);

// Select the items in the Menu List for the current app
export const selectMenuListItemsArray = createSelector(
  selectBreadcrumbs,
  selectItems,
  selectAppId,
  (crumbs, allItems, id) => {
    const output = allItems[id]
      ?.items
      ?.map((i) => allItems[i])
      ?.filter((i) => i?.Name?.value === 'Menu List')['0']
      ?.items
      ?.map((j) => allItems[j]);
    return output;
  },
);

// Select the items in the Screen List for the current app
export const selectScreenListItemsArray = createSelector(
  selectItems,
  selectAppId,
  (allItems, id) => {
    const output = allItems[id]
      ?.items
      ?.map((i) => allItems[i])
      ?.filter((i) => i?.Name?.value === 'Screen List')['0']
      ?.items
      ?.map((j) => allItems[j]);
    // console.log({ output });
    return output;
  },
);

// Select the Screen List id for the current app
export const selectScreenListId = createSelector(
  selectBreadcrumbs,
  selectItems,
  (crumbs, allItems) => {
    const appCrumbs = crumbs.slice(0, 3);
    const appId = appCrumbs.slice(-1)[0];
    const appListItems = allItems[appId].items;
    const screenListId = appListItems
      .map((i) => allItems[i])
      ?.filter((i) => i?.Name?.value === 'Screen List')['0']?.id;
    // console.log({ screenListId });
    return screenListId;
  },
);

// Select the breadcrumbs to the Screen List for the current app
export const selectScreenListCrumbs = createSelector(
  selectPublicBreadcrumbs,
  selectPublicItems,
  selectScreenListId,
  (crumbs, allItems, id) => {
    const appCrumbs = crumbs.slice(0, 3);
    const screenListCrumbs = [...appCrumbs, id];
    // console.log({ screenListCrumbs });
    return screenListCrumbs;
  },
);

export const selectGrandParentPageItemsArray = createSelector(
  selectGrandParentPage,
  selectPrivateItems,
  (page, items) => page.items.map((i) => items[i]),
);

export const selectParentPageItemsArray = createSelector(
  selectParentPage,
  selectPrivateItems,
  (page, items) => page.items.map((i) => items[i]),
);

export const selectItemsInTableArray = createSelector(
  selectPage,
  selectPrivateItems,
  (page, items) => page
    .items
    .map((i) => items[i])
    .reduce((acc, cur) => [...acc, ...cur.items], []),
);

// Returns array of IDs
export const selectItemsArray = createSelector(
  selectPrivateItems,
  (items) => {
    const list = Object.keys(items).reduce(
      (prev, curr) => [
        ...prev,
        curr,
      ],
      [],
    );
    return list;
  },
);

// Returns array of item OBJECTS
export const selectItemsObjectArray = createSelector(
  selectPrivateItems,
  (items) => {
    const list = Object.keys(items).reduce(
      (prev, curr) => [
        ...prev,
        items[curr],
      ],
      [],
    );
    // console.log('list', list);
    return list;
  },
);

export const selectCurrentTableItems = createSelector(
  selectItemsInGraphArray,
  selectPrivateItems,
  (current, allItems) => current.map((i) => allItems[i])
    .reduce((acc, cur) => {
      if (cur) {
        acc[cur.id] = cur;
      }
      return acc;
    }, {}),
);

export const selectCurrentTableItemsArray = createSelector(
  selectCurrentTableItems,
  selectPrivateItems,
  (items) => {
    // console.log('items', items);
    const list = Object.keys(items).reduce(
      (prev, curr) => [
        ...prev,
        curr,
      ],
      [],
    );
    // console.log('list', list);
    return list;
  },
);

export const selectAllCurrentTableItemsArray = createSelector(
  selectCurrentTableItemsArray,
  selectPrivateItems,
  (currentItems, allItems) => currentItems.map((i) => allItems[i])
    .reduce((acc, cur) => {
      if (cur) {
        acc[cur.id] = cur;
      }
      return acc;
    }, {}),
);

export const selectTableItemsArray = createSelector(
  selectAllCurrentTableItemsArray,
  (items) => {
    // console.log('items', items);
    const list = Object.keys(items).reduce(
      (prev, curr) => [
        ...prev,
        curr,
      ],
      [],
    );
    // console.log('list', list);
    return [[], list];
  },
);

// TableGroup
export const selectCompletedItemsCount = createSelector(
  selectPrivateItems,
  selectItemsInTableArray,
  selectItemsInGraphArray,
  selectTableView,
  (allItems, tableItems, graphItems, tableView) => (tableView ? tableItems : graphItems)
    .reduce((acc, item) => (allItems[item].completed ? acc + 1 : acc), 0),
);

// Graph
export const selectActiveItemsCount = createSelector(
  selectPrivateItems,
  selectItemsInTableArray,
  selectItemsInGraphArray,
  selectTableView,
  (allItems, tableItems, graphItems, tableView) => (tableView ? tableItems : graphItems)
    .reduce((acc, item) => (allItems[item].completed ? acc : acc + 1), 0),
);

export const selectMaxIdFromItemsArray = (items) => {
  // console.log("  items", items);
  const maxVal = items.length
    ? items.reduce((max, i) => {
      const p = parseInt(i, 10);
      // console.log('max', max, 'p', p, 'typeof p', typeof p, 'typeof max', typeof max);
      if (p && !Number.isNaN(p)) {
        return p > max ? p : max;
      }
      return max;
    }, -Infinity)
    : 0;
  // console.log('MAX', max, 'type', typeof max);
  return parseInt(maxVal, 10);
};

export const selectMaxId = createSelector(
  selectItemsArray,
  selectMaxIdFromItemsArray,
);

export const selectMaxOrderFromItemsArray = (items) => (items.length
  ? items.reduce((max, p) => {
    // console.log({ order: p?.order });
    // console.log({ p });
    if (p) {
      return (p.order > max ? p.order : max);
    }
    return max;
  }, -Infinity) : 0);

export const selectMaxOrder = createSelector(
  selectPageItemsArray,
  selectMaxOrderFromItemsArray,
);

export const selectMinOrderFromItemsArray = (items) => {
  const newItems = items.length ? items.reduce((min, p) => {
    if (p) {
      return (p.order < min ? p.order : min);
    }
    return min;
  }, Infinity) : 0;
  // console.log({ items, newItems });
  return newItems;
};

export const selectMinOrder = createSelector(
  selectPageItemsArray,
  selectMinOrderFromItemsArray,
);

export const selectModifiedItemsFromItemsArray = (items) => Object.keys(items).filter((k) => {
  const r = items[k] && items[k].dirty;
  // console.log('r=', r, 'items[k]=', items[k], 'k=', k);
  return r;
}).map((k) => items[k]).reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});

export const selectAllModifiedItems = createSelector(
  selectItemsObjectArray,
  selectModifiedItemsFromItemsArray,
);

export const selectPageModifiedItems = createSelector(
  selectPageItemsArray,
  selectModifiedItemsFromItemsArray,
);

export const selectNextItem = createSelector(
  selectNextId,
  selectPrivateItems,
  (nextId, items) => items[nextId],
);

export const selectSecondBreadcrumbId = createSelector(
  selectPrivateBreadcrumbs,
  (crumbs) => crumbs.slice(1, 2),
);

export const selectPageType = createSelector(
  selectPage,
  (page) => page.type,
);

export const selectAll = (state) => state.undo.present.private.entities.items[ALL];

export const selectUsedItemsCount = createSelector(
  selectPrivateItems,
  selectAll,
  (items, all) => {
    let total = 1; // All
    try {
      const getCount = (arr) => {
        let count = 0;
        arr.forEach((i) => {
          if (items[i]) {
            // console.log(`${i} (${items[i].Name.value}) IS used. (${count})`);
            count += 1;
            count += getCount(items[i].items);
          }
        });
        return count;
      };
      total += getCount(all.items);
      // console.log('total', total);
      // console.log('all', all);
    } catch (error) {
      console.log(`selectUsedItemsCount error: ${error}`);
    }
    return total;
  },
);

export const selectDefaultTypeFields = (_, type) => DEFAULT_FIELDS[type];
// export const selectDynamicTypeFields = () => NEW_FIELDS;

export const selectTemplateOutput = createSelector(
  selectSelectedItem,
  selectPrivateItems,
  selectAll,
  (item, items, all) => {
    try {
      // console.log('all', all);
      // console.log('items', items);
      const sourceId = pickBy(all.items, (i) => items[i] !== undefined
        && items[i].Name !== undefined && items[i].Name.value === item.Source.value)[0];
      const sourceItem = items[sourceId];
      // console.log('sourceItem', sourceItem);
      const out = item.Header
        && `${item.Header.value}\n${sourceItem.items.reduce((previous, key) => {
          let newValue = item.Template.value;
          if (newValue.includes('{{')) {
            newValue = newValue.replace('{{Count}}', Object.keys(sourceItem.items).length || '')
              .replace('{{Length}}', Object.keys(sourceItem.items).length || '');
            // console.log('key', key);
            // console.log('items[key]', items[key]);
            const line = Object.keys(items[key]).reduce((p, k) => {
              if (k[0].toUpperCase() === k[0]) {
                return p.replace(`{{${k}}}`, items[key][k].value || '');
              }
              return p;
            }, newValue);
            return previous + line;
          }
          return previous + newValue;
        }, '')}`;
      // console.log('out:'); // eslint-disable-line no-console
      // console.log(out); // eslint-disable-line no-console
      return out;
    } catch (e) {
      // console.warn(e); // eslint-disable-line no-console
      // Probably not a template item
      return '';
    }
  },
);

// Return array of all types defined (built ins + custom) //  + "New Type"
export const selectTypes = createSelector(
  selectAll,
  selectPrivateItems,
  (all, items) => {
    const [typesIndex] = all.items.filter((i) => items[i] && items[i].type === 'Types');
    const typeItems = items[typesIndex] ? items[typesIndex].items : [];
    const typesList = typeItems.map((i) => items[i].Name.value);
    return [
      ...DEFAULT_FIELDS.Types.Type.values.map((a) => a.toLowerCase()),
      ...typesList,
      //            '<New Type>'
    ];
  },
);

// Given a type, return its fields
// eg selectTypeFields('Contact') => ['address', 'phone number', etc.]
export const selectTypeFields = (state, type) => createSelector(
  (state) => state,
  selectAll,
  selectPrivateItems,
  selectPageType,
  (state, all, items, pageType) => {
    if (type === undefined) {
      // console.log('returning defaults because type is undefined');
      return DEFAULT_FIELDS.Types.Type.values;
    }
    // console.log('type', type);
    // console.log('pageType', pageType);
    // console.log('state', state);
    // console.log('all', all);
    // console.log('items', items);
    if (type === 'All') {
      return {
        Description: {
          value: 'All',
          type: 'Text',
        },
        type: 'All',
      };
    }
    try {
      const [typesIndex] = all.items.filter((i) => items[i] && items[i].type === 'Types');
      // console.log('typesIndex', typesIndex);
      const [currentTypeIndex] = items[typesIndex].items.filter((i) => items[i] && items[i].Name
        && items[i].Name.value === pageType);
      // console.log('currentTypeIndex', currentTypeIndex);
      if (!currentTypeIndex) {
        // console.log('returning default fields');
        return DEFAULT_FIELDS[type];
      }
      const fields = items[currentTypeIndex].items.reduce((obj, item) => {
        // console.log('obj', obj);
        // console.log('item', item);
        try {
          return {
            ...obj,
            [items[item].Name.value]: {
              'value': items[item]['Default value'].value,
              'type': items[item].Type ? items[item].Type.value : items[item]['Base type'].value,
            },
          };
        } catch (e) {
          // console.log(e);
        }
        return obj;
      }, {});
      // console.log('fields', fields);
      return fields;
    } catch (e) {
      // console.log(e);
      return DEFAULT_FIELDS[type];
    }
  },
)(state);

// Given a type, return its list of possible values
// eg [All/Types/Contacts/Title]   selectTypeValues('Title') => ['Mr.', 'Mrs.', 'Sir', etc.]
// eg [All]                        selectTypeValues('Contacts') => ['Adam', 'Ben', 'Chris', etc.]
export const selectTypeValues = (state, type, keyName) => createSelector(
  (state) => state,
  selectAll,
  selectPrivateItems,
  selectTypes,
  selectSelectedItem,
  (state, all, items, types, item) => {
    if (type === undefined || type === 'All') {
      return [];
    }
    const lowerType = type.toLowerCase();
    if (lowerType === 'yesno') {
      return ['Yes', 'No'];
    }
    if (lowerType === 'list') {
      return ['Yes', 'No'];
    }
    if (lowerType === 'type') {
      return types;
    }
    try {
      // Find All/Contacts:
      const [myTypesIndex] = all.items.filter((i) => items[i] && items[i].type === type);
      if (myTypesIndex !== undefined) {
        const [myTypesIndex] = all.items.filter((i) => items[i] && items[i].type === type);
        const currentTypeIndexes = items[myTypesIndex].items;
        const currentTypeObjs = currentTypeIndexes.map((i) => items[i]);
        const currentTypeObjsFiltered = currentTypeObjs.filter((i) => i !== undefined);
        const currentTypeNames = currentTypeObjsFiltered.map((i) => i.Name);
        const out = currentTypeNames.map((i) => i.value);
        return out;
      }
      const [typesIndex] = all.items.filter((i) => items[i] && items[i].type === 'Types');
      const typeIndexes = items[typesIndex].items;
      const typeObjs = typeIndexes.map((i) => items[i]);
      const ourType = item.type; // Contact
      const [ourTypesItem] = typeObjs.filter((i) => i.Name.value === ourType);
      const currentTypeIndexes = ourTypesItem.items;
      const currentTypeObjs = currentTypeIndexes.map((i) => items[i]);
      const currentTypeObjsFiltered = currentTypeObjs.filter((i) => i !== undefined
        && i.Name.value === keyName);
      const currentTypeNames = currentTypeObjsFiltered.map((i) => i.Values);
      const value = currentTypeNames.map((i) => i.value);
      const out = value[0].split(' ');
      return out;
    } catch (e) {
      // console.log(e);
      return [];
    }
  },
)(state);
