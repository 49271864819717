// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
// import { globalHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';

// import ItemsSplitList from '../components/ItemsSplitList';
import ItemLists from '../components/ItemLists';
import Breadcrumbs from '../components/Breadcrumbs';
import ViewSettings from '../components/ViewSettings';
import {
  selectPage,
  selectPageId,
} from '../selectors';
import EditItem from '../components/EditItem';
import ErrorBoundary from '../components/ErrorBoundary';

function Home({
  debug,
}) {
  const page = useSelector(selectPage);
  const pageId = useSelector(selectPageId);

  // https://github.com/remix-run/remix/discussions/5999
  /*
  useEffect(() => {
    globalHistory.listen(({ action }) => {
      // console.log('action', action);
      if (action === 'PUSH') {
        // console.log('action PUSH');
        // this.resetNav();
      }
    });
  });
  */
  return (
    <ErrorBoundary>
      <Box>
        <ViewSettings className='tour-view-settings' />
      </Box>
      <Box display='flex' flexDirection='row' className='tour-breadcrumbs'>
        <Breadcrumbs debug={debug} />
      </Box>
      <Box>
        {/* <ItemsSplitList debug={debug} /> */}
        <ItemLists debug={debug} />
        {!page.hideFields
        && <EditItem id={pageId} />}
      </Box>
    </ErrorBoundary>
  );
}

Home.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default Home;
