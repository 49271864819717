// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
} from '@material-ui/core';
import { triggerPopPrivateBreadcrumbSaga, triggerPopPublicBreadcrumbSaga } from '../actions/items';
import {
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectIsPrivate,
  selectBreadcrumbs,
  selectItemsForBreadcrumbs,
} from '../selectors';

const TOP = 'All';
const GRID = 2;
function BreadcrumbsLinks({ debug }) {
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  const isPrivate = useSelector(selectIsPrivate);
  const breadcrumbs = useSelector(selectBreadcrumbs);
  // console.log('breadcrumbs', breadcrumbs);
  const items = useSelector(selectItemsForBreadcrumbs);
  /*
  const items = useSelector((state) => {
    const entities = isPrivate
      ? state.undo.present.private.entities : state.undo.present.public.entities;
    const crumbs = breadcrumbs.map(
      i => entities.items[i],
    );
    return crumbs;
  });
*/
  const dispatch = useDispatch();
  const popPrivateBreadcrumb = (breadcrumbsIn, index) => dispatch(
    triggerPopPrivateBreadcrumbSaga({ breadcrumbs: breadcrumbsIn, index }),
  );
  const popPublicBreadcrumb = (breadcrumbsIn, index) => dispatch(
    triggerPopPublicBreadcrumbSaga({ breadcrumbs: breadcrumbsIn, index }),
  );
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: GRID * 2,
    margin: `0 ${GRID}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'floralwhite',
    alignItems: 'center',

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  // console.log('items', items);
  const itemsLeft = items.length > 1 ? items.slice(0, -1) : items;
  // console.log('itemsLeft', itemsLeft);
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      style={{ flexWrap: 'wrap' }}
    >
      {itemsLeft
        .filter((i) => i !== undefined)
        .map((crumb, index) => {
          // console.log(' crumb', crumb, index);
          // const adjustedIndex = index > 1 ? index - 1 : index;
          if (!crumb) {
            return undefined;
          }
          // console.log('');
          return (
            <React.Fragment key={crumb.id}>
              <Draggable
                key={crumb.id}
                draggableId={String(crumb.id)}
                alignItems='center'
                index={index}
              >
                {(dragProvided, dragSnapshot) => (
                  <Box
                    sx={{ flexWrap: 'wrap' }}
                    m={0}
                    p={0}
                    ref={dragProvided.innerRef}
                    justify='center'
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragProvided.draggableProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragProvided.dragHandleProps}
                    style={getItemStyle(
                      dragSnapshot.isDragging,
                      dragProvided.draggableProps.style,
                    )}
                  >
                    <Button
                      m={0}
                      p={0}
                      justify='center'
                      style={{
                        textTransform: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                      href='#'
                      onClick={() => {
                        if (privateBreadcrumbs.length === publicBreadcrumbs.length) {
                          if (isPrivate) {
                            // console.log('isPrivate, equal lengths, popPublic too');
                            popPublicBreadcrumb(breadcrumbs, index);
                          } else {
                            // console.log('!isPrivate, equal lengths, popPrivate too');
                            popPrivateBreadcrumb(breadcrumbs, index);
                          }
                        }
                        if (isPrivate) {
                          // console.log('isPrivate');
                          popPrivateBreadcrumb(breadcrumbs, index);
                        } else {
                          // console.log('!isPrivate');
                          popPublicBreadcrumb(breadcrumbs, index);
                        }
                      }}
                    >
                      {index > 0 ? crumb.Name.value : TOP}
                      {debug && ` {${crumb.id}}`}
                    </Button>
                  </Box>
                )}
              </Draggable>
              {breadcrumbs.length > 1 && '≫'}
            </React.Fragment>
          );
        })}
    </Box>
  );
}

BreadcrumbsLinks.propTypes = {
  debug: PropTypes.bool.isRequired,
};
export default BreadcrumbsLinks;
