// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
} from '@material-ui/core';

import {
  selectBreadcrumbs,
  selectItems,
  selectItemsForBreadcrumbs,
  selectNextId,
  selectMaxId,
  selectUsedItemsCount,
} from '../selectors';
import BreadcrumbsTailsHorizontal from './BreadcrumbsTailsHorizontal';
import BreadcrumbsTailsList from './BreadcrumbsTailsList';

function BreadcrumbsTails({
  debug,
}) {
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const items = useSelector(selectItemsForBreadcrumbs);
  // const privatetemsCount = useSelector(state => Object.keys(selectPrivateItems(state)).length);
  // const publicItemsCount = useSelector(state => Object.keys(selectPublicItems(state)).length);
  const maxId = useSelector(selectMaxId);
  const nextId = useSelector(selectNextId);
  const usedItemsCount = useSelector(selectUsedItemsCount);

  const tail = breadcrumbs.length > 0 ? items[breadcrumbs.length - 1]
    && (items[breadcrumbs.length - 1] || undefined) : undefined;
  // const parentItem = useSelector(state => selectPrivateItems(state)[tail?.parent]);
  // const parentItem = useSelector(state => selectPrivateItems(state)[tail?.parent]);
  // const parentItem = items[tail?.parent];
  const parentItem = useSelector((state) => selectItems(state)[tail?.parent]);

  // console.log('tail', tail);
  // console.log(' parentItem', parentItem);

  return (
    <Box
      display='flex'
      flexDirection='row'
      style={{ flexWrap: 'wrap' }}
    >
      {parentItem?.items?.length >= 1
        ? <BreadcrumbsTailsList debug={debug} />
        : <BreadcrumbsTailsHorizontal debug={debug} />}
      <Box>
        {debug
        && `
        NextId: ${nextId}
        MaxId: ${maxId}
        Items: allItemsCount
        UsedItems: ${usedItemsCount}`}
      </Box>
    </Box>
  );
}

BreadcrumbsTails.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default BreadcrumbsTails;
