// (C) Copyright 2020 MediaWink, LLC

import * as SagaEffects from 'redux-saga/effects';
import moment from 'moment';
import achievehubAPI from '../apis/achievehubAPI';
import {
  ADD_CHILD_SAGA,
  ADD_FIELD_SAGA,
  ADD_ITEM_SAGA,
  ADD_ITEM_PRIVATE,
  ADD_ITEM_TO_BOTTOM_SAGA,
  ADD_ITEM_TO_TOP_SAGA,
  DELETE_FIELD_SAGA,
  DOWN_ITEM,
  DOWN_ITEM_SAGA,
  GET_IMAGE_SAGA,
  PUT_AND_UPDATE_SAGA,
  MOVE_ITEM_PRIVATE,
  MOVE_ITEM_SAGA,
  MOVE_PAGE_PRIVATE,
  MOVE_PAGE_SAGA,
  REMOVE_ITEM_PRIVATE,
  REMOVE_ITEM_SAGA,
  SAVE_PUBLIC_BREADCRUMBS_SAGA,
  SAVE_PRIVATE_BREADCRUMBS_SAGA,
  SET_HIDE_ADD_BOTTOM,
  SET_HIDE_ADD_TOP,
  SET_HIDE_ADD_BOTTOM_SAGA,
  SET_HIDE_ADD_TOP_SAGA,
  SET_TABLE_VIEW,
  SET_TABLE_VIEW_GROUPS,
  SET_TABLE_VIEW_GROUPS_SAGA,
  SET_TABLE_VIEW_SAGA,
  SET_NEXT_ID,
  TOGGLE_ITEM_PRIVATE,
  TOGGLE_ITEM_SAGA,
  UP_ITEM,
  UP_ITEM_SAGA,
  UPDATE_ITEM_SAGA,
  UPDATE_FIELD_SAGA,
  UPDATE_ITEM_BY_ID_SAGA,
  SET_COLUMNS_PER_PAGE,
  SET_COLUMNS_PER_PAGE_SAGA,
  SET_SHOW_VIEW_SETTINGS_SAGA,
  SET_SHOW_VIEW_SETTINGS,
  SET_FIRST_VISIBLE_COLUMN,
  SET_FIRST_VISIBLE_COLUMN_SAGA,
  SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA,
} from '../action-types/items';
import {
  addChild,
  addField,
  deleteField,
  pushPrivateBreadcrumb,
  setSelection,
  updateItem,
} from '../actions/items';
import {
  INJECT_ITEMS_INTO_REDUX_SAGA,
  SET_LASTMODTIME,
} from '../action-types/session';
import {
  selectDispenseForId,
  selectFirstColumnOnCurrentPage,
  selectLogin,
  selectMaxOrderFromItemsArray,
  selectMinOrderFromItemsArray,
  selectLastmodtime,
  selectNextId,
  selectSettings,
  selectPrivateItemById,
  selectItemsByIds,
  selectPage,
  selectPageItemsArray,
  selectPrivateItems,
  selectLastColumnOnCurrentPage,
} from '../selectors';
import {
  injectItemsIntoReduxSaga,
  updateDirtyItemsToDatabaseSaga,
} from './session';

export const {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} = SagaEffects;

export function* updateItemSaga(action) {
  console.log('updateItemSaga action=', action);
  const { item } = action.data;
  let { keyName } = action.data;
  console.log(' item', item);
  console.log(' keyName', keyName);
  try {
    const login = yield select(selectLogin);
    keyName = keyName || 'Name';
    const inData = (keyName && item) ? {
      item_id: item.id,
      keyName,
      item: {
        ...item,
        [keyName]: {
          ...item[keyName],
          // item: newValue,
          // keys,
          type: item[keyName].type,
        },
        dirty: false,
        firstVisibleColumn: 0,
      },
    } : {
      item_id: item?.id,
      item,
    };
    const lastmodtime = yield select(selectLastmodtime);
    const payload = {
      item: inData.item,
      item_id: inData.item_id,
      lastmodtime,
      ...login,
    };
    yield put(updateItem({ ...payload }));
    console.log('achievehubAPI.do update_item payload', payload);
    const data = yield call(achievehubAPI.do, 'update_item', payload);
    console.log('update_item returned data:', data);
    // update last-mod-time right away to avoid ghostly behaviors
    const task2 = yield put({ type: SET_LASTMODTIME, data });
    // If items returned, update Redux (new IDs plus data from other users/computers)
    if (data.status === 200) {
      console.log('INJECT into REDUX');
      yield injectItemsIntoReduxSaga(
        {
          ...action,
          insert: true,
          data: payload,
          dbItemsObj: {
            data: {
              private: {
                ...payload.item,
              },
            },
          },
          type: INJECT_ITEMS_INTO_REDUX_SAGA,
        },
      );
    } else {
      console.log('SKIP ---- INJECT into REDUX', payload);
    }
    return task2;
  } catch (e) {
    console.warn('error', e);
  }
  console.log({ return: false });
  return false;
}

export function* updateFieldSaga(action) {
  // console.log('updateFieldSaga', action);
  const { item } = action.data;
  let { keyName } = action.data;

  try {
    const login = yield select(selectLogin);
    keyName = keyName || 'Name';
    const inData = (keyName && item) ? {
      item_id: item.id,
      keyName,
      item: {
        ...item,
        [keyName]: {
          ...item[keyName],
          // item: newValue,
          // keys,
          type: item[keyName].type,
        },
      },
    } : {
      item_id: item.id,
      item,
    };
    const lastmodtime = yield select(selectLastmodtime);
    const payload = {
      item: inData.item,
      item_id: inData.item_id,
      lastmodtime,
      ...login,
    };
    yield put(updateItem({ ...payload }));
    const data = yield call(achievehubAPI.do, 'update_item', payload);
    // update last-mod-time right away to avoid ghostly behaviors
    const task2 = yield put({ type: SET_LASTMODTIME, data });
    // If items returned, update Redux (new IDs plus data from other users/computers)
    if (data.data.items) {
      yield injectItemsIntoReduxSaga(
        { ...action, dbItemsObj: data, type: INJECT_ITEMS_INTO_REDUX_SAGA },
      );
    }
    return task2;
  } catch (e) {
    console.warn('error', e);
  }
  return false;
}

export function* updateItemByIdSaga(action) {
  console.log({ updateItemByIdSagaAction: action });
  // let { keyName, item } = action.data;
  const { id } = action.data;
  const item = yield select(selectPrivateItemById, id);
  const updateItemAction = {
    ...action.data,
    data: {
      item, // : { ...action.data.item },
    },
    type: UPDATE_ITEM_SAGA,
  };
  yield updateItemSaga(updateItemAction);
}

export function* savePrivateBreadcrumbsSaga(action) {
  // console.log('savePrivateBreadcrumbsSaga', action);
  const { breadcrumbs } = action;

  try {
    const login = yield select(selectLogin);
    const settings = yield select(selectSettings);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings,
      },
    };
    // console.log('achievehubAPI.do set_account_data', { ...dataIn });
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
  } catch (e) {
    console.warn('catch', e);
  }
}

export function* savePublicBreadcrumbsSaga(action) {
  // console.log('savePublicBreadcrumbsSaga', action);
  const { breadcrumbs } = action;

  try {
    const login = yield select(selectLogin);
    const settings = yield select(selectSettings);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings,
      },
    };
    // console.log('achievehubAPI.do set_account_data', { ...dataIn });
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
  } catch (e) {
    console.warn('catch', e);
  }
}

export function* addChildSaga(action) {
  // console.log('addChildSaga', action);
  const { breadcrumbs, id, Name } = action;
  yield put(addChild(breadcrumbs, id, Name));
  yield put(pushPrivateBreadcrumb(id));
  /*
  yield saveBreadcrumbsSaga({
    ...action,
    breadcrumbs: [
      ...breadcrumbs,
      id,
    ],
    type: SAVE_BREADCRUMBS_SAGA,
  });
*/
}

export function* addFieldSaga(action) {
  console.log({ addFieldAction: action });
  const { payload } = action;
  const { id } = payload;
  yield put(addField(payload));
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* addItemSaga(action) {
  console.log('addItemSaga', action);
  const { item } = action;
  // console.log('item', item);
  try {
    const { value } = action.item.Name;
    // console.log('value', value);
    let nextId = yield select(selectNextId);
    const login = yield select(selectLogin);
    const parent = action.parent ?? action.breadcrumbs.slice(-1)[0];
    const lastmodtime = yield select(selectLastmodtime);
    const newItem = {
      ...item,
      items: [],
      Name: {
        type: 'Text',
        value,
      },
      parent,
      id: nextId,
    };
    let dataIn = {
      ...login,
      lastmodtime,
      items: [newItem],
    };
    // console.log('dataIn', dataIn);
    const lastColumn = yield select(selectLastColumnOnCurrentPage);
    // console.log('lastColumn', lastColumn);
    yield put({
      type: ADD_ITEM_PRIVATE,
      data: {
        ...action,
        item: newItem,
        nextId,
      },
    });
    nextId += 1;
    // console.log('>newItem', newItem);
    yield put({ type: SET_NEXT_ID, id: nextId });
    const page = yield select(selectPage); // Student
    const parentObj = yield select(selectPrivateItemById, parent);
    // console.log('>parentObj', parentObj);
    if (parentObj.Dispensers) {
      if (page.tableViewGroups) {
        try {
          // https://github.com/redux-saga/redux-saga/issues/306
          // TODO: Send ONE request to the DB
          const firstColumn = yield select(selectFirstColumnOnCurrentPage);
          // const { firstColumnItems } = firstColumn;
          // console.log('>firstColumn', firstColumn);
          const { items } = lastColumn?.length > 0 ? lastColumn : firstColumn;
          // const { items } = lastColumn;
          // console.log(' items', items);
          for (let i = 0; i < items.length; i += 1) {
            // console.log('>for i', i, 'item', items[i]);
            const firstItemId = firstColumn.items[i];
            const lastItemId = items[i];
            const firstColumnItem = yield select(selectPrivateItemById, firstItemId);
            const lastColumnItem = yield select(selectPrivateItemById, lastItemId);
            // console.log('>>firstColumnItem', firstColumnItem);
            // console.log('>>lastColumnItem', lastColumnItem);
            if (lastColumnItem) {
              const condition = firstColumnItem?.DispenseCondition?.value?.toLowerCase();
              const dayOfWeek = moment(newItem?.Name?.value, 'MM/DD/YYYY')
                .format('dddd')
                .toLowerCase();
              // console.log('condition', condition);
              // console.log('dayOfWeek', dayOfWeek);
              const skip = (
                (condition?.includes('day') && !condition?.includes(dayOfWeek))
                || (condition?.startsWith('odd week')
                  && (moment(newItem?.Name?.value, 'MM/DD/YYYY').format('w') % 2) !== 1)
                || (condition?.startsWith('even week')
                  && (moment(newItem?.Name?.value, 'MM/DD/YYYY').format('w') % 2) !== 0)
              );
              const character = skip ? '' : '❓';
              // const character = '❓';
              // console.log('DISPENSE_ON', parentObj?.DispenseOn?.value);
              const dispensed = parentObj?.DispenseOn?.value?.toLowerCase() === 'click' ? character
                : yield select(selectDispenseForId, lastItemId, value);
              // console.log('>>dispensed', dispensed, '>>ParentObj', parentObj);
              const newCopiedItem = {
                ...lastColumnItem,
                items: [],
                Name: {
                  type: 'Text',
                  value: dispensed,
                },
                parent: newItem.id,
                completed: false,
                group: lastColumnItem.id,
                id: nextId,
              };
              dataIn = {
                ...dataIn,
                items: [...dataIn.items, newCopiedItem],
              };
              newCopiedItem.id = nextId;
              const newAddData = {
                type: ADD_ITEM_PRIVATE,
                data: {
                  ...action,
                  item: newCopiedItem,
                  breadcrumbs: [...action.breadcrumbs, newItem.id],
                  nextId,
                },
              };
              // console.log('>>NEW', newAddData);
              yield put(newAddData); // TAKE and action, not put it
              nextId += 1;
              yield put({ type: SET_NEXT_ID, id: nextId });
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    }
    if (dataIn.items.length > 0) {
      // console.log('add_items dataIn', dataIn);
      const addResults = yield call(achievehubAPI.do, 'add_items', dataIn);
      yield put({ type: SET_LASTMODTIME, data: addResults });
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* addItemToBottomSaga(action) {
  // console.log('addItemToBottomSaga', action);
  const { resolve, reject } = action;
  try {
    const values = action?.data?.item?.Name?.value?.split('\n')?.filter((v) => v !== '');
    // console.log('values', values);
    for (let index = 0; index < values.length; index += 1) {
      const value = values[index];
      const page = yield select(selectPage);
      const parentId = action.data.parent;
      const parent = yield select(selectPrivateItemById, parentId);
      const items = yield select(selectItemsByIds, parent.items);
      const order = yield selectMaxOrderFromItemsArray(items);
      const newOrder = order + 1;
      console.log({
        value, page, order, newOrder,
      });
      const addDataAction = {
        ...action.data,
        item: {
          ...action.data.item,
          order: newOrder,
          Name: {
            ...action.data.item.Name,
            value,
          },
        },
        type: ADD_ITEM_SAGA,
      };
      // console.log('call addItemSaga', addDataAction);
      yield call(addItemSaga, addDataAction);
      if (resolve) {
        // console.log('resolve()');
        resolve();
      }
    }
  } catch (e) {
    // console.log('e', e);
    if (reject) {
      reject();
    }
    console.warn(e);
  }
}

export function* addItemToTopSaga(action) {
  console.log('addItemToTop', action);
  const { resolve, reject } = action;
  try {
    const values = action.data.item.Name.value.split('\n').filter((v) => v !== '').reverse();
    console.log({ values });
    for (let index = 0; index < values.length; index += 1) {
      const value = values[index];
      const page = yield select(selectPage);
      const pageItemsArray = yield select(selectPageItemsArray);
      console.log({ page, value, pageItemsArray });
      const parentId = action.data.parent;
      const parent = yield select(selectPrivateItemById, parentId);
      const items = yield select(selectItemsByIds, parent.items);
      console.log({ selectMinOrderFromItemsArray: true });
      const order = yield selectMinOrderFromItemsArray(items);
      console.log({ order });
      const newOrder = order - 1;
      console.log({ order, newOrder });
      const addData = {
        ...action.data,
        item: {
          ...action.data.item,
          order: newOrder,
          Name: {
            ...action.data.item.Name,
            value,
          },
        },
        type: ADD_ITEM_SAGA,
      };
      // yield addItemSaga(addData);
      yield call(addItemSaga, addData);
      const newPageItemsArray = yield select(selectPageItemsArray);
      const newPrivateItems = yield select(selectPrivateItems);
      console.log({ newPageItemsArray }); // bug--old # of items in array
      console.log({ newPrivateItems }); // ok--new # of items in page's items
      if (resolve) {
        resolve();
      }
    }
  } catch (e) {
    if (reject) {
      reject();
    }
    console.warn(e);
  }
}

export function* deleteFieldSaga(action) {
  const { payload } = action;
  const { id } = payload;
  yield put(deleteField(payload));
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* downItemSaga(action) {
  const { data } = action;
  yield put({ type: DOWN_ITEM, data });
  yield updateDirtyItemsToDatabaseSaga();
}

export function* moveItemSaga(action) {
  const { data } = action;
  yield put({ type: MOVE_ITEM_PRIVATE, data });
  yield updateDirtyItemsToDatabaseSaga();
}

export function* movePageSaga(action) {
  // console.log('MOVE_PAGE_SAGA', action);
  const { resolve, reject } = action;
  try {
    const { data } = action;
    yield put({ type: MOVE_PAGE_PRIVATE, data });
    yield updateDirtyItemsToDatabaseSaga();
    if (resolve) {
      resolve();
    }
  } catch (e) {
    if (reject) {
      reject();
    }
    console.warn(e);
  }
}

export function* removeItemSaga(action) {
  // console.log('removeItemSaga', action);
  const { data } = action;
  try {
    yield put({ type: REMOVE_ITEM_PRIVATE, data });
    const login = yield select(selectLogin);
    const lastmodtime = yield select(selectLastmodtime);
    const input = {
      lastmodtime,
      item_id: data.id,
      ...login,
    };
    /* let { data } = */ yield call(achievehubAPI.do, 'delete_item', input);
  } catch (e) {
    // TODO: Add to offline actions queue
    console.warn(e);
  }
}

export function* upItemSaga(action) {
  const { data } = action;
  yield put({ type: UP_ITEM, data });
  yield updateDirtyItemsToDatabaseSaga();
}

export function* toggleItemSaga(action) {
  // console.log('toggleItemSaga', action);
  const { id } = action.data;
  yield put({ type: TOGGLE_ITEM_PRIVATE, data: action.data });
  const updateItemAction = {
    type: UPDATE_ITEM_BY_ID_SAGA,
    data: {
      id,
    },
  };
  yield updateItemByIdSaga(updateItemAction);
}

export function* putAndUpdateSaga(action) {
  const { id, type } = action.data;
  const { data } = action;
  yield put({ type, data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setTableViewSaga(action) {
  yield put(setSelection(null, null));
  const { id } = action.data;
  yield put({ type: SET_TABLE_VIEW, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setColumnsPerPageSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_COLUMNS_PER_PAGE, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setFirstVisibleColumnSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_FIRST_VISIBLE_COLUMN, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setHideViewSettingsSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_SHOW_VIEW_SETTINGS, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setHideAddBottomSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_HIDE_ADD_BOTTOM, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setHideAddTopSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_HIDE_ADD_TOP, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* setTableViewGroupsSaga(action) {
  const { id } = action.data;
  yield put({ type: SET_TABLE_VIEW_GROUPS, data: action.data });
  const updateItemAction = { type: UPDATE_ITEM_BY_ID_SAGA, data: { id } };
  yield updateItemByIdSaga(updateItemAction);
}

export function* getImageSaga(action) {
  // console.log('getImage', action);
  const { url } = action;
  const login = yield select(selectLogin);
  const payload = {
    ...login,
    url,
  };
  const data = yield call(achievehubAPI.do, 'get_image', payload);
  // console.log('data (returned from get_image saga)', data);
  const ascii = data.data;
  // const ascii = data.data.toString('base64');
  // const ascii = Buffer.from(data.data, 'base64').toString('utf-8');
  // const ascii = btoa(data.data); // , 'base64').toString('utf-8');
  // const ascii = Buffer.from(data.data, 'ascii'); // .toString('utf-8');
  // console.log('ascii', ascii);
  const base64 = `data:image/png;base64,${ascii}`;
  action.func(base64);
  return base64;
}

export function* syncItemsFromGoogleDocSaga(action) {
  // console.log('syncItemsFromGoogleDocSaga', action);
  const { navigate } = action;
  const login = yield select(selectLogin);
  // console.log('login', login);
  const payload = {
    ...login,
  };
  try {
    const data = yield call(achievehubAPI.do, 'get_data_from_google_doc', payload);
    // console.log('data?.data', data?.data);
    // console.log('data?.data?.list', data?.data?.list);
    // const names = data?.data?.list?.split(',');
    const names = data?.data?.list;
    // const len = 2; // TEMP
    const len = names.length;
    for (let n = 0; n < len; n += 1) {
      const Name = names[n];
      // console.log('Name', Name);
      const {
        breadcrumbs,
        id,
      } = action;
      // console.log('yield put(addItemSaga(');
      // console.log('id', id);
      // console.log(' Name', Name);
      const newValue = Name;
      // console.log(' breadcrumbs', breadcrumbs);

      const nextId = yield select(selectNextId);
      const parent = action.breadcrumbs.slice(-1)[0];
      const newCrumbs = [
        ...breadcrumbs,
        id,
      ];
      // console.log('newCrumbs', newCrumbs);
      const addDataAction = {
        ...login,
        item_id: nextId,
        parent: parent.id,
        breadcrumbs: newCrumbs,
        nextId,
        value: newValue,
        item: {
          Name: {
            type: 'Text',
            value: newValue,
          },
          type: 'Text',
          // group,
        },
        type: ADD_ITEM_TO_BOTTOM_SAGA,
      };
      // console.log('addDataAction', addDataAction);
      yield call(addItemToBottomSaga, { data: addDataAction });
    }
  } catch (e) {
    // console.log('RETHROW?: Error attempting to get content from Google Doc:', e);
    // throw (e);
    navigate('/signin');
  }
}

export function* watchItemSagas() {
  yield takeEvery(ADD_CHILD_SAGA, addChildSaga);
  yield takeEvery(ADD_FIELD_SAGA, addFieldSaga);
  yield takeEvery(ADD_ITEM_SAGA, addItemSaga);
  yield takeEvery(ADD_ITEM_TO_BOTTOM_SAGA, addItemToBottomSaga);
  yield takeEvery(ADD_ITEM_TO_TOP_SAGA, addItemToTopSaga);
  yield takeEvery(DELETE_FIELD_SAGA, deleteFieldSaga);
  yield takeEvery(DOWN_ITEM_SAGA, downItemSaga);
  yield takeEvery(PUT_AND_UPDATE_SAGA, putAndUpdateSaga);
  yield takeEvery(MOVE_ITEM_SAGA, moveItemSaga);
  yield takeEvery(MOVE_PAGE_SAGA, movePageSaga);
  yield takeEvery(REMOVE_ITEM_SAGA, removeItemSaga);
  yield takeEvery(SAVE_PUBLIC_BREADCRUMBS_SAGA, savePublicBreadcrumbsSaga);
  yield takeEvery(SAVE_PRIVATE_BREADCRUMBS_SAGA, savePrivateBreadcrumbsSaga);
  yield takeEvery(SET_COLUMNS_PER_PAGE_SAGA, setColumnsPerPageSaga);
  yield takeEvery(SET_SHOW_VIEW_SETTINGS_SAGA, setHideViewSettingsSaga);
  yield takeEvery(SET_FIRST_VISIBLE_COLUMN_SAGA, setFirstVisibleColumnSaga);
  yield takeEvery(SET_HIDE_ADD_BOTTOM_SAGA, setHideAddBottomSaga);
  yield takeEvery(SET_HIDE_ADD_TOP_SAGA, setHideAddTopSaga);
  yield takeEvery(SET_TABLE_VIEW_GROUPS_SAGA, setTableViewGroupsSaga);
  yield takeEvery(SET_TABLE_VIEW_SAGA, setTableViewSaga);
  yield takeEvery(SYNC_ITEMS_FROM_GOOGLE_DOC_SAGA, syncItemsFromGoogleDocSaga);
  yield takeEvery(GET_IMAGE_SAGA, getImageSaga);
  yield takeEvery(TOGGLE_ITEM_SAGA, toggleItemSaga);
  yield takeEvery(UP_ITEM_SAGA, upItemSaga);
  yield takeEvery(UPDATE_ITEM_BY_ID_SAGA, updateItemByIdSaga);
  yield takeEvery(UPDATE_ITEM_SAGA, updateItemSaga);
  yield takeEvery(UPDATE_FIELD_SAGA, updateFieldSaga);
}
