// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import {
  Box,
  Button,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { create, all } from 'mathjs';

import {
  // setPublicBreadcrumbs,
  setHtml,
  triggerSetPublicBreadcrumbsSaga,
  triggerSetAndSaveVariableSaga,
} from '../actions/items';
import Image from '../components/Image';
import Photo from '../components/Photo';
import {
  selectBreadcrumbs,
  selectDataListMap,
  selectPage,
  selectItems,
  selectPageItemsArray,
  selectMissingItems,
  selectNextId,
} from '../selectors';
import { syncFromDatabaseSaga } from './session';
import { convertImageToText, processReceiptText } from '../utils/ocr';
import {
  PLAY_APP_SAGA,
  // SAVE_PUBLIC_BREADCRUMBS_SAGA,
  SET_AND_SAVE_VARIABLE_SAGA,
  SYNC_FROM_DATABASE_SAGA,
} from '../action-types';
import { selectLogin } from '../selectors/session';
import {
  addItemToBottomSaga,
  updateItemSaga,
  // savePublicBreadcrumbsSaga,
} from './items';
import {
  pushBreadcrumbSaga,
  // setPublicBreadcrumbsSaga,
} from './breadcrumb';
import { substituteVariables } from '../utils/variables';
import InputTextField from '../components/InputTextField.jsx';
import InputTextFieldCamera from '../components/InputTextFieldCamera.jsx';
import InputTextFieldSelect from '../components/InputTextFieldSelect.jsx';
import List from '../components/List.jsx';

const math = create(all);
let vars = {};
/*
const getTimestamp = () => {
  const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
  const d = new Date();
  // eg 2022-09-02 07:45:57
  return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.
    getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
};
*/
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function pretty(string) {
  return capitalizeFirstLetter(string.toLowerCase()).replaceAll('_', ' ');
}

export function* setAndSaveVariableSaga(action) {
  console.log({ setAndSaveVariableSagaSction: action });
  const { data } = action;
  const { key, value } = data;
  const breadcrumbs = yield select(selectBreadcrumbs);
  const nextId = yield select(selectNextId);
  const login = yield select(selectLogin);
  const dataListMap = yield select(selectDataListMap);
  console.log({ dataListMap });
  const firstDataPair = dataListMap ? Object.entries(dataListMap)?.[0] : [];
  if (!dataListMap[key]) {
    const newEntry = {
      ...login,
      item_id: nextId,
      parent: firstDataPair?.[1]?.parent,
      breadcrumbs,
      nextId,
      value,
      item: {
        Name: {
          type: 'Text',
          value: `${key}=${value}`,
        },
        type: 'Text',
        group: undefined,
      },
    };
    yield call(addItemToBottomSaga, { data: newEntry });
  } else {
    let needUnique = value && value?.startsWith('PICK NEW ');
    let loop = 0;
    let finalValue = substituteVariables(value, vars, dataListMap);
    // TODO: Remove hard coded 100
    while (loop < 100 && needUnique) {
      finalValue = substituteVariables(value, vars, dataListMap);
      if (finalValue !== dataListMap[key].value) {
        // Different value... no need to try again...
        needUnique = false;
      }
      loop += 1;
    }
    const addition = {
      [dataListMap[key].Name.value]: finalValue,
    };
    const newVariables = {
      ...vars,
      ...addition,
    };
    const pair = `${key}=${finalValue}`;
    dataListMap[key].value = finalValue;
    vars = newVariables;

    // IF data[key].Name.value has =, save to DB as well
    if (dataListMap[key].Name.value.includes('=')) {
      const updateObj = {
        item_id: dataListMap[key].id,
        Name: pair,
        data: {
          item: {
            ...dataListMap[key],
            Name: {
              type: 'Text',
              value: pair,
            },
          },
        },
      };
      yield call(updateItemSaga, updateObj);
    }
  }
  // return updated
  yield vars;
}

export function* playAppSaga(action) {
  const { data } = action;
  const { dispatch } = data;
  const { inputObj } = data;
  const { setInput } = data;
  console.log({ playAppSaga: 1, inputObj, action });
  const setPublicBreadcrumbsSaga = (crumbs) => dispatch(
    triggerSetPublicBreadcrumbsSaga({ breadcrumbs: crumbs }),
  );
  let actionValue;
  let name;
  let breadcrumbs;
  // const { /* data, */ resolve, reject } = action;
  const page = yield select(selectPage);
  let allItems = yield select(selectItems);
  let dataListMap = yield select(selectDataListMap);
  let items = yield select(selectPageItemsArray);

  // LOAD ALL THE APP
  let missingItems = yield select(selectMissingItems);

  const newPublicId = page.id;
  const login = yield select(selectLogin);
  if (missingItems.length > 0) {
    const obj = {
      // ...action,
      data: login,
      item: 1,
      insert: true,
      recursive: true,
      // privateItem: newPrivateId,
      publicItem: newPublicId,
      type: SYNC_FROM_DATABASE_SAGA,
    };
    yield syncFromDatabaseSaga(obj);
    allItems = yield select(selectItems);
    items = yield select(selectPageItemsArray);
    dataListMap = yield select(selectDataListMap);
    missingItems = yield select(selectMissingItems);
  }

  /*
  const [parent, setParent] = useState();
  const [screen, setScreen] = useState();
*/
  let target;
  let parent;
  let screen;
  let done = false;
  /*
  const popPublicBreadcrumbSaga = (crumbs, i) => dispatch(
    triggerPopPublicBreadcrumbSaga({ breadcrumbs: crumbs, index: i }),
  );
*/
  // const dispatch = useDispatch();
  /*
  const startApp = () => dispatch(
    triggerPlayAppSaga(),
  );
  */
  // const [tick, setTick] = useState(0);
  // const items = useSelector(selectPageItemsArray);
  // const breadcrumbs = useSelector(selectBreadcrumbs);
  // const dataListMap = useSelector(selectDataListMap);
  // const [data] = useState(dataListMap);

  const setAndSaveVariable = (key, value) => dispatch(
    triggerSetAndSaveVariableSaga({ key, value }),
  );
  function* setCrumbsGenerator(inputValue) {
    console.log({ setCrumbsGenertor: inputValue });
    try {
      yield setPublicBreadcrumbsSaga(inputValue);
      console.log({ setPublicBreacrumbsSaga: inputValue });
      // yield call(playAppSaga, {});
    } catch (e) {
      // console.log('error', e);
    }
  }

  const clickHandler = (buttonAction) => function functionTemplate() {
    console.log({ clickHandlerButtonAction: buttonAction });
    // Get button text and set breadcrumbs accordingly
    // For each input, setAndSaveVariable()
    const entries = Object.entries(inputObj.input ?? {});
    console.log({ inputObj, entries });
    for (let n = 0; n < entries.length; n += 1) {
      const [key, value] = entries[n];
      // call(setAndSaveVariableSaga, { data: { key, value } });
      console.log({
        n, setAndSaveVariable: false, key, value,
      });
      // TODO - JDO
      // FIX (should work in budgetHub, and not set #= in QuizHub)
      if (inputObj.input === 'TODO-FIX-UM, NEVER-0.00 ') {
        setAndSaveVariable(key, value);
      }
    }
    const screensCrumbs = breadcrumbs.slice(0, -1);
    const screensId = screensCrumbs.slice(-1)[0];
    const screensObj = allItems[screensId];
    const screenItems = screensObj.items;
    let newId = -1;
    for (let m = 0; m < screenItems.length; m += 1) {
      const id = screenItems[m];
      const screenObj = allItems[id];
      if (screenObj?.Name?.value === buttonAction) {
        newId = screenObj.id;
      }
      // return null;
    }

    const newCrumbs = [...screensCrumbs, newId];
    console.log({ newCrumbs });
    // let setCrumbs = (obj) => {
    const setCrumbs = setCrumbsGenerator(newCrumbs);
    setCrumbs.next();

    window.scrollTo(0, 0);
    return null; // generator requires return value
  };

  const photoHandler = async (image) => {
    console.log({ photoHandler: image });
    if (image === 'NOWAY') {
      const text = await convertImageToText(image);
      /* const { total } = */processReceiptText(text);
    }
    const total = '123.45';
    // input.PURCHASE_AMOUNT = '1111';
    // input.DEPOSIT_AMOUNT = '2222';
    // setAndSaveVariable('PURCHASE_AMOUNT', total);

    // inputObj.input = { ...inputObj.input, 'PURCHASE_AMOUNT': total };
    setInput(total);
    // clickHandler('Start')();
    // clickHandler('Submit')();
    // AND save image to users's DB
    // const entry = getTimestamp(); // new Date().toLocaleDateString();
    // vars = setAndSaveVariable('LAST IMAGE LAST', image);
    // vars = setAndSaveVariable(`${entry} IMAGE`, image);
  };
  /*
  const onTextFieldChange = (event) => {
    // event.preventDefault();
    const { value: v } = event.target;
    // eslint-disable-next-line arrow-body-style
    // input = { ...input, [event.target.id]: v };
    console.log({ ontextFieldChange: event, input: inputObj.input, v });
    // const d = event.data;
    event.target.value = v;
    setInput((old) => ({
      ...old,
      inputObj: { input: v },
    }));
    // setInput({ ...inputObj.input, [event.target.id]: v });
  };
*/

  for (let n = 0; n < items.length && !done; n += 1) {
    const i = items[n];
    if (!i) {
      break;
    }
    if (!done) {
      const privateVisibility = false; // TODO: Remove hard-coding
      target = 'target TODO';
      if (i.Name.value === target) {
        parent = i.id;
        yield call(pushBreadcrumbSaga, {
          data: {
            id: i.id,
            name: i.Name.value,
            privateVisibility,
          },
        });
        items = yield select(selectPageItemsArray);
        done = true;
      }
      if (i.Name.value === 'Screen List') {
        parent = i.id;
        yield call(pushBreadcrumbSaga, {
          data: {
            id: i.id,
            name: i.Name.value,
            privateVisibility,
          },
        });
        items = yield select(selectPageItemsArray);
        done = true;
      }
      if (i.Name.value === 'Start') {
        parent = i.id;
        yield call(pushBreadcrumbSaga, {
          data: {
            id: i.id,
            name: i.Name.value,
            privateVisibility,
          },
        });
        items = yield select(selectPageItemsArray);
        done = true;
      }
      if (parent && !screen) {
        screen = i.id;
        yield call(pushBreadcrumbSaga, {
          data: {
            id: i.id,
            name: i.Name.value,
            privateVisibility,
          },
        });
        items = yield select(selectPageItemsArray);
        done = true;
      }
    }
  }

  // input = newCrumbs
  // And parse the screen lines... Once...
  // const input = 'tempTextField';
  let linesCount = 0;
  let lines = {};
  let random = false;
  let out = [];
  let randomOut = [];
  // UPDATE to get the latest items
  const sortedItems = items.sort((a, b) => a.order - b.order);
  let value = '';
  for (let n = 0; n < sortedItems.length; n += 1) {
    const i = sortedItems[n];

    // no-loop-function
    // https://stackoverflow.com/a/36932815
    // sortedItems.map(function* forEachSortedItems(i) {
    // items.sort((a, b) => a.order - b.order).map(async (i) => {
    if (!i) {
      break;
    }
    const line = i?.Name?.value;
    const array = line?.split('=');
    let val;
    if (array && array.length < 2) {
      const inputValue = i.Name.value;
      const output = substituteVariables(inputValue, vars, dataListMap);
      val = (<Box key={`output-${i.id}`}>{output}</Box>);
    }
    let key = array?.[0];
    value = array.slice(1).join('=');
    if (key === 'Button' || key === 'BUTTON') {
      const nameInput = value.split(':')[0];
      const actionInput = value.split(':')[1] || nameInput;
      name = substituteVariables(nameInput, vars, dataListMap);
      actionValue = substituteVariables(actionInput, vars, dataListMap);
      breadcrumbs = yield select(selectBreadcrumbs);
      val = (
        <Button
          key={i.id}
          margin='small'
          onClick={clickHandler(actionValue)}
          style={{
            textTransform: 'none',
          }}
          variant='contained'
        >
          {name}
        </Button>
      );
    }
    if (line.startsWith('IF ')) {
      const split = line.split(' THEN '); //  || line.split(', '); // lists also use ,
      const rawCondition = split[0];
      const condition = substituteVariables(rawCondition, vars, dataListMap)
        .replace('IF ', '');
      const simplified = String(math.evaluate(condition));
      const actionPart = split[1];
      if (simplified === 'true') {
        const splitAction = actionPart.split('=');
        [key, value] = splitAction;
      }
    }
    if (['InputCamera', 'AskCamera'].includes(key)) {
      const variableToRead = line?.split('=').slice(1).join('=');
      // const valueRead = substituteVariables(variableToRead, vars, dataListMap);
      const label = pretty(variableToRead);
      val = (
        <InputTextFieldCamera
          label={label}
          value=''
          onChange={(event) => {
            const variableToSet = line?.split('=').slice(1).join('=');
            console.log({ key, value: event.target.value, variableToSet });
            setAndSaveVariable(variableToSet, event.target.value);
          }}
        />
      );
    }
    if (['Input', 'Ask'].includes(key)) {
      const variableToRead = line?.split('=').slice(1).join('=');
      const valueRead = substituteVariables(variableToRead, vars, dataListMap);
      const type = dataListMap[variableToRead]?.Type?.value ?? 'Text'; //  = MAPPINGS;
      const label = pretty(variableToRead);
      if (type === 'Text') {
        val = (
          <InputTextField
            label={label}
            // value={valueRead}
            value=''
            onChange={(event) => {
              const variableToSet = line?.split('=').slice(1).join('=');
              console.log({ key, value: event.target.value, variableToSet });
              setAndSaveVariable(variableToSet, event.target.value);
            }}
          />
        );
      } else {
        const options = dataListMap[type].items.map((item) => ({
          value: item.Name.value,
          label: item.Name.value,
        }));
        val = (
          <InputTextFieldSelect
            label={label}
            value={valueRead}
            options={options}
            onChange={(event) => {
              const variableToSet = line?.split('=').slice(1).join('=');
              console.log({ key, value: event.target.value, variableToSet });
              setAndSaveVariable(variableToSet, event.target.value);
            }}
          />
        );
      }
      // console.log({ input: line, tf: tfObj.tf, val });
    }
    if (key === 'Image' || key === 'IMAGE') {
      const inputValue = value;
      const output = substituteVariables(inputValue, vars, dataListMap);
      // Crop: Technique #1: http://cssglobe.com/3-easy-and-fast-css-techniques-for-faux-image/
      // const src = output.replace(/\?.*/, '');
      if (output.startsWith('http')) {
        val = (
          <Image
            key={i.id}
            width={200}
            height={200}
            scale={5.0}
            src={output}
            alt='ALT TEXT'
            referrerPolicy='no-referrer'
          />
        );
      } else {
        // Return text instead
        val = output;
      }
      // val = 'IMAGE GOES HERE';
    }
    if (['List'].includes(key)) {
      // Get the list name
      // Iterate over the list
      // show keys and values
      // val = (<Photo key='tick' handler={photoHandler} />);
      // const variableToList = key.replace('List=', '');
      const variableToList = value;
      const [k, v1] = variableToList.replace(']', '').split('[');
      const v = substituteVariables(v1, vars, dataListMap);
      console.log({
        v1, v, k, dataListMap, variableToList,
      });
      if (v) {
        const listItems = dataListMap[k].items;
        const list = listItems.map((it) => {
          console.log({ it, two: true });
          if (it?.Name?.value === v) {
            const mappedItems = Object.values(it.items);
            console.log({ mappedItems });
            return (<List key={it.id} list={mappedItems} />);
          }
          return null;
        });
        val = (<ul>{list}</ul>);
      } else {
        const mappedItems = Object.values(dataListMap[variableToList]?.items);
        // dataListMap[variableToList].bag = mappedItems.map(
        //   (it) => it.Name.value,
        // );
        // dataListMap[variableToList].bag = mappedItems.map(
        //   (it) => it.Name.value,
        // );
        const list = mappedItems.map((it) => {
          console.log({ it });
          return (<li key={it.key}>{it.value}</li>);
        });
        val = (<ul>{list}</ul>);
      }
      // val = (<ListItem key={i.id} item={i} />);
    }
    if (['Photo'].includes(key)) {
      val = (<Photo key='tick' handler={photoHandler} />);
    }
    if (key === 'RANDOM') {
      if (value
        && (value.toLowerCase() === 'false'
          || value.toLowerCase() === 'off'
          || value.toLowerCase() === 'no')) {
        random = false;
      } else {
        random = true;
      }
      val = null;
    }
    if (key.startsWith('RESET ')) {
      const variableToReset = key.replace('RESET ', '');
      const [k, v1] = variableToReset.replace(']', '').split('[');
      const v = substituteVariables(v1, vars, dataListMap);
      if (v) {
        const listItems = dataListMap[k].items;
        listItems.forEach((item) => {
          if (item?.Name?.value === v) {
            const mappedItems = Object.values(item.items);
            item.bag = mappedItems.map(
              (it) => it.Name.value,
            );
            val = null;
          }
        });
      } else {
        const mappedItems = Object.values(dataListMap[variableToReset]?.items);
        dataListMap[variableToReset].bag = mappedItems.map(
          (it) => it.Name.value,
        );
        val = null;
      }
    }
    if (line.startsWith('SHOW ')) {
      const screenName = line.replace('SHOW ', '');
      breadcrumbs = yield select(selectBreadcrumbs);
      console.log({ screenName, breadcrumbs });
      clickHandler(screenName)();
      done = true;
    }
    if (key === 'Title') {
      val = (
        <h1 key={i.id}>{value}</h1>
      );
    }

    // VARIABLE=value
    if (dataListMap[key]) {
      console.log({ dataListMap, key, value });
      yield call(setAndSaveVariableSaga, { data: { key, value } });
    }

    if (['Action'].includes(key)) {
      val = (
        <Box key={`action-${i.id}`} pad='medium'>
          {`Action: ${value}`}
        </Box>
      );
    }
    // Now ADD val...
    if (val) {
      if (random) {
        const right = Math.random() < 0.5;
        randomOut = right ? [...randomOut, val] : [val, ...randomOut];
      } else {
        out = [...out, val];
      }
    }
    lines = (
      <div key={linesCount} style={{ display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={2}>
          {[...out, ...randomOut]}
        </Stack>
      </div>
    );
    linesCount += 1;
  }
  yield put(setHtml(lines));
  return lines;
}

export function* watchPlayAppSagas() {
  yield takeLatest(PLAY_APP_SAGA, playAppSaga);
  yield takeLatest(SET_AND_SAVE_VARIABLE_SAGA, setAndSaveVariableSaga);
}
