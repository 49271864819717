// (C) Copyright 2020 MediaWink, LLC

/* eslint-disable no-await-in-loop */

import React, { useCallback, useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  triggerMovePageSaga,
  triggerSetPrivateBreadcrumbsSaga,
} from '../actions/items';
import BreadcrumbsLinks from './BreadcrumbsLinks';
import BreadcrumbsTails from './BreadcrumbsTails';
import { selectPage } from '../selectors/items';
import Spinner from './spinner/spinner';
import {
  selectPrivateBreadcrumbs,
  selectPublicBreadcrumbs,
  selectIsPrivate,
} from '../selectors';

const GRID = 8;
function Breadcrumbs({
  debug,
}) {
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();
  const privateBreadcrumbs = useSelector(selectPrivateBreadcrumbs);
  const publicBreadcrumbs = useSelector(selectPublicBreadcrumbs);
  const isPrivate = useSelector(selectIsPrivate);
  const crumbs = isPrivate ? privateBreadcrumbs : publicBreadcrumbs;
  const page = useSelector((state) => selectPage(state));
  useEffect(() => () => window.speechSynthesis.cancel());
  const onDragEnd = useCallback((result) => {
    const setPrivateBreadcrumbs = (data, resolve, reject) => dispatch(
      triggerSetPrivateBreadcrumbsSaga(data, resolve, reject),
    );
    const movePage = (data, resolve, reject) => dispatch(
      triggerMovePageSaga(data, resolve, reject),
    );
    // console.log('result', result);
    const { source, destination } = result;
    if (source
        && destination
        && source.index > 0 // Not "All"
        && source.index > destination.index) {
      // Get ids from indexes
      // console.log('crumbs', crumbs);
      const from = crumbs[source.index];
      const to = crumbs[destination.index - 1];
      if (to >= 0) {
        // console.log('new Promise: Breadcrumbs');
        trackPromise(
          new Promise((resolve, reject) => {
            movePage({ from, to }, resolve, reject);

            const breadcrumbs = crumbs.slice(0, destination.index);
            setPrivateBreadcrumbs({ breadcrumbs }, resolve, reject);
          })
            .catch((/* e */) => {
              // console.log('Error', e);
            }),
        );
      }
    }
  }, [crumbs, dispatch]);
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    alignItems: 'center',
    display: 'flex',
    padding: GRID,
    overflow: 'auto',
  });
  return (
    <>
      {promiseInProgress && <Spinner text='Moving data around...' />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          key='0'
          direction='horizontal'
          droppableId='tail'
        >
          {(dropProvided, snapshot) => (
            <Box
              ref={dropProvided.innerRef}
              style={{
                ...getListStyle(snapshot.isDraggingOver),
                flexWrap: 'wrap',
                flexDirection: 'row',
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...dropProvided.droppableProps}
            >

              {!page.hideCrumbsBody
                && (
                  <Box
                    className='tour-breadcrumb-links'
                    style={{ flexWrap: 'wrap' }}
                  >
                    <BreadcrumbsLinks debug={debug} />
                  </Box>
                )}

              <Box>
                {!page.hideCrumbsTail
                  && (
                    <Box
                      style={{ flexWrap: 'wrap' }}
                      display='flex'
                      alignItems='stretch'
                      className='tour-breadcrumb-tail'
                    >
                      <BreadcrumbsTails debug={debug} />
                    </Box>
                  )}
              </Box>

              <Box>
                {dropProvided.placeholder}
              </Box>
            </Box>
          )}

        </Droppable>
      </DragDropContext>
    </>
  );
}

Breadcrumbs.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default Breadcrumbs;
