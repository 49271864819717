// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import {
  Box,
} from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

import rootReducer from './reducers';
import defaultData from './default';
import rootSaga from './sagas';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
// import migrate from './utils/migrate';

const cookies = new Cookies();
const cookie = cookies.get('achievehub.com_login');

const dataObjects = (cookie && cookie.keepSignedIn)
  ? defaultData
  : JSON.parse(localStorage.getItem('data')) || defaultData;
const migratedDataObjects = {
  private: dataObjects,
  public: dataObjects,
}; // migrate(dataObject);

try {
  if (!migratedDataObjects.private.login || !migratedDataObjects.private.login.keepSignedIn) {
    migratedDataObjects.private.login = {
      keepSignedIn: true,
      isLoggedIn: false,
    };
  }
} catch (e) {
  console.error(e);
}

if (cookie && cookie.keepSignedIn) {
  // console.log('use cookie');
  migratedDataObjects.private.login = cookie;
} else {
  // console.log('do not use cookie');
}

const undoableDataObject = {
  undo: {
    past: [],
    present: migratedDataObjects, // .private,
    future: [],
  },
};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  undoableDataObject,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(rootSaga);

// console.log('render App');

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <Box height='1000px' />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// console.log('serviceWorker.unregister()');
serviceWorker.unregister();
