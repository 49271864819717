// (C) Copyright 2020 MediaWink, LLC

import { produce } from 'immer';
/*
TODO: Remove CONSTANTS if no longer needed?
import {
} from '../action-types';
*/

const DOWN = 1;
const UP = -1;

const createItemsReducer = (itemsType) => (state = {}, action = null) => {
  const upDown = (dir) => (draftState) => {
    const { id } = action.data;
    const originalOrder = draftState[id].order;
    const originalParentID = draftState[id].parent;
    const parent = draftState[originalParentID];
    const list = parent.items;
    const keys = Object.keys(draftState);
    let minMax = dir * Infinity; // DELTA (min-up, max-down)
    let closestItem;
    const orders = {};
    keys.map((key) => {
      if (list.includes(parseInt(key, 10))) {
        const newOrder = draftState[key].order;
        orders[newOrder] = (orders[newOrder] || 0) + 1;
        let offset = 0;
        while (orders[newOrder + offset] > 1) {
          // Push it out...
          draftState[key].order += 1;
          draftState[key].dirty = true;
          orders[newOrder + offset] = 1;
          offset += 1;
          orders[newOrder + offset] = (orders[newOrder + offset] || 0) + 1;
        }
        if ((dir === -1 && newOrder > minMax && newOrder < originalOrder)
          || (dir === 1 && newOrder < minMax && newOrder > originalOrder)) { // DELTA
          // Find item to swap with
          closestItem = draftState[key];
          minMax = closestItem.order; // DELTA
        }
      }
      return draftState[key];
    });
    if (closestItem) {
      draftState[id].order = minMax; // DELTA
      draftState[id].dirty = true;
      draftState[closestItem.id].order = originalOrder;
      draftState[closestItem.id].dirty = true;
    }
  };

  let out;
  // console.log(`switch ${action.type}`, itemsType);
  switch (action.type) {
    case `IMPORT_JSON_${itemsType}`:
      // console.log(`items: IMPORT_JSON_${itemsType}, json:`, JSON.parse(action.json));
      if (itemsType === 'PUBLIC') {
        out = JSON.parse(action.json).public.entities.items;
      }
      if (itemsType === 'PRIVATE') {
        out = JSON.parse(action.json).private.entities.items;
      }
      // console.log({ insert: false, out });
      return out;

    case `INSERT_JSON_${itemsType}`:
      // console.log(`INSERT_JSON_${itemsType}, json:`, JSON.parse(action.json));
      if (itemsType === 'PUBLIC') {
        out = { ...state, ...JSON.parse(action.json).public.entities.items };
      }
      if (itemsType === 'PRIVATE') {
        out = { ...state, ...JSON.parse(action.json).private.entities.items };
      }
      // console.log({ insert: true, out });
      return out;

    case `ADD_CHILD_${itemsType}`: {
      return ({
        ...state,
        [action.id]: {
          ...state[action.id],
          items: [],
        },
      });
    }
    case `ADD_FIELD_${itemsType}`: {
      return produce(state, (draftState) => {
        const {
          id, name, type, value, keys,
        } = action.payload;
        draftState[id][name] = {
          type,
          value,
          keys,
        };
      });
    }
    case `ADD_ITEM_${itemsType}`: {
      return produce(state, (draftState) => {
        const { breadcrumbs, nextId } = action.data;
        draftState[breadcrumbs[breadcrumbs.length - 1]]?.items?.push(nextId);
        draftState[nextId] = action.data.item;
        draftState[nextId].id = nextId;
      });
    }
    case `DELETE_FIELD_${itemsType}`: {
      return produce(state, (draftState) => {
        const { id, name } = action.payload;
        delete draftState[id][name];
      });
    }
    case `MOVE_ITEM_${itemsType}`: {
      // console.log(`MOVE_ITEM_${itemsType}`);
      return produce(state, (draftState) => {
        const { from, to } = action.data;
        const fromOrder = draftState[from].order;
        const toOrder = draftState[to].order;
        const dir = fromOrder > toOrder ? UP : DOWN;
        const minOrder = Math.min(fromOrder, toOrder);
        const maxOrder = Math.max(fromOrder, toOrder);
        const id = from;
        const originalParentID = draftState[id].parent;
        const parent = draftState[originalParentID];
        const list = parent.items;
        const keys = Object.keys(draftState);
        keys.map((key) => {
          if (list.includes(parseInt(key, 10))) {
            const { order } = draftState[key];
            if (order >= minOrder && order <= maxOrder) {
              draftState[key].order -= dir; // draftState[key].order + 1;
              draftState[key].dirty = true;
            }
          }
          return draftState[key];
        });
        draftState[from].order = state[to].order;
        draftState[from].dirty = true;
      });
    }
    case `MOVE_PAGE_${itemsType}`: {
      // console.log(`MOVE_PAGE_${itemsType}`);
      return produce(state, (draftState) => {
        const { from, to } = action.data;
        const fromItem = draftState[from];
        // console.log('MOVE_PAGE_*', action);
        // console.log('fromItem', fromItem);
        // console.log('fromItem.parent', fromItem.parent);
        const { parent } = fromItem;

        // Add it to toItem
        draftState[to].dirty = true;
        draftState[to].items.push(from);

        // Remove it from PARENT OF fromIndex
        draftState[parent].dirty = true;
        draftState[parent].items = draftState[parent].items.filter(
          (value) => Number(value) !== Number(from),
        );
        draftState[from].dirty = true;
        draftState[from].parent = to;
        // console.log('parent', parent);
        // console.log('fromItem.parent', fromItem.parent);
        // console.log('draftState[from].dirty', draftState[from].dirty);
        // console.log('draftState[fromItem.parent].dirty', draftState[fromItem.parent].dirty);
        // console.log('draftState[to].dirty', draftState[to].dirty);
        // console.log('state', state);
        // console.log('draftState[from].items', draftState[from].items);
        // console.log('draftState[to].items', draftState[to].items);
        // console.log('draftState[parent].items', draftState[parent].items);
      });
    }
    case `DOWN_ITEM_${itemsType}`: {
      return produce(state, upDown(DOWN));
    }
    case `REMOVE_ITEM_${itemsType}`: {
      // console.log('REMOVE_ITEM');
      return produce(state, (draftState) => {
        const { id } = action.data;
        // console.log({ action });
        // console.log({ draftState });
        // console.log({ id });
        // const id = action.delete.item_id;
        // const originalOrder = draftState[id].order;
        // const list = state[breadcrumbs[breadcrumbs.length-1]]['items'];
        const { parent } = draftState[id];
        // console.log({ parent });
        if (parent) {
          // console.log('before', state[parent].items);
          draftState[parent].items = state[parent].items.filter(
            (value) => Number(value) !== Number(id),
          );
          // console.log('after', draftState[parent].items);
        }
        delete draftState[id];
      });
    }
    case `RENAME_ITEM_${itemsType}`: {
      return produce(state, (draftState) => {
        if (action.newName !== action.Name) {
          draftState[action.id].Name = action.newName;
        }
      });
    }
    case `SET_COLUMNS_PER_PAGE_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          columnsPerPage: action.data.columnsPerPage,
        },
      };
    }
    case `SET_FIRST_VISIBLE_COLUMN_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          firstVisibleColumn: action.data.firstVisibleColumn,
        },
      };
    }
    case `SET_SHOW_VIEW_SETTINGS_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          showViewSettings: action.data.showViewSettings,
        },
      };
    }
    case `SET_HIDE_ADD_BOTTOM_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideAddBottom: action.data.hideAddBottom,
        },
      };
    }
    case `SET_HIDE_ADD_TOP_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideAddTop: action.data.hideAddTop,
        },
      };
    }
    case `SET_HIDE_CRUMBS_BODY_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideCrumbsBody: action.data.hideCrumbsBody,
        },
      };
    }
    case `SET_HIDE_CRUMBS_TAIL_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideCrumbsTail: action.data.hideCrumbsTail,
        },
      };
    }
    case `SET_HIDE_DELETE_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideDelete: action.data.hideDelete,
        },
      };
    }
    case `SET_HIDE_EDIT_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideEdit: action.data.hideEdit,
        },
      };
    }
    case `SET_HIDE_FIELDS_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideFields: action.data.hideFields,
        },
      };
    }
    case `SET_HIDE_CHECKMARK_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideCheckmark: action.data.hideCheckmark,
        },
      };
    }
    case `SET_HIDE_CHILDREN_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideChildren: action.data.hideChildren,
        },
      };
    }
    case `SET_TABLE_VIEW_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          tableView: action.data.tableView,
        },
      };
    }
    case `SET_TABLE_VIEW_GROUPS_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          tableViewGroups: action.data.tableViewGroups,
        },
      };
    }
    case `TOGGLE_ITEM_${itemsType}`: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          completed: !state[action.data.id].completed,
        },
      };
    }
    case `UP_ITEM_${itemsType}`: {
      return produce(state, upDown(UP));
    }
    case `UPDATE_ITEM_${itemsType}`: {
      return produce(state, (draftState) => {
        draftState[action.data.item_id] = action.data.item;
      });
    }
    case `CLEAR_DIRTY_BIT_FOR_ITEM_${itemsType}`: {
      // console.log(`CLEAR_DIRTY_BIT_FOR_ITEM_${itemsType}`);
      return produce(state, (draftState) => {
        draftState[action.data.item_id].dirty = false;
      });
    }
    default: {
      return state;
    }
  }
};

export default createItemsReducer;
