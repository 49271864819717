// (C) Copyright 2020 MediaWink, LLC

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
  Box,
  Checkbox,
  FormGroup,
  // FormControl,
  FormControlLabel,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Spinner from './spinner/spinner';
import {
  resetPasswordAsync,
  // triggerSetAutomationSettingSaga,
  triggerLogoutSaga,
  triggerDeleteAccountSaga,
  triggerSetBackupSettingSaga,
} from '../actions/session';

function UserSettings() {
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const loginState = useSelector((state) => state.undo.present.private.login);
  const settings = useSelector((state) => state.undo.present.private.settings);
  const logout = (data, resolve, reject) => dispatch(
    triggerLogoutSaga(data, resolve, reject),
  );
  const reset = (payload, resolve, reject) => dispatch(
    resetPasswordAsync(payload, resolve, reject),
  );
  const deleteAccount = (payload, resolve, reject) => dispatch(
    triggerDeleteAccountSaga(payload, resolve, reject),
  );
  const setBackup = (payload, resolve, reject) => dispatch(
    triggerSetBackupSettingSaga(payload, resolve, reject),
  );
  /*
  const setAutomation = (payload, resolve, reject) => dispatch(
    triggerSetAutomationSettingSaga(payload, resolve, reject),
  );
  */
  const [message, setMessage] = useState('');
  // const handleSubmit = ({ reset, setMessage }) => (event) => {
  const [deletePressed, setDeletePressed] = useState(false);
  const handleDeleteAccount = (event) => {
    console.log('handleDeleteAccount(event)', event);
    event.preventDefault();
    if (!deletePressed) {
      console.log('!deletePressed');
      setDeletePressed(true);
      setMessage(`Do you really want to delete this account?  All data will be deleted.
       Be sure to create and download a backup file first.  To delete the account,
       press Delete Account one more time.`);
    } else {
      console.log('deletePressed');
      const data = {
        password,
        new_password: newPassword,
      };
      console.log('new Promise: UserSettings');
      trackPromise(
        new Promise((resolve, reject) => {
          console.log(' new promise...');
          deleteAccount(data, resolve, reject);
          console.log(' after new promise...');
        })
          .catch((e) => {
            console.log('Delete Account failed.  Try again.', e);
            setMessage('Delete Account failed.  Try again.');
          }),
      );
      let p;
      console.log('new Promise: UserSettings');
      trackPromise(
        p = new Promise((resolve, reject) => {
          logout(loginState, resolve, reject);
        }),
      );
      p.catch(() => {
      });
    }
  };
  const handleUpdatePassword = (event) => {
    // console.log('event', event);
    event.preventDefault();
    setMessage('');
    if (newPassword !== newPassword2) {
      setMessage('New passwords must match.');
      return;
    }
    const data = {
      password,
      new_password: newPassword,
    };
    console.log('new Promise: UserSettings');
    trackPromise(
      new Promise((resolve, reject) => {
        reset(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Incorrect current password.  Try again.');
        }),
    );
  };
  const setBackupsChecked = (data) => {
    // console.warn('setBackupsChecked', data);
    console.log('new Promise: UserSettings');
    trackPromise(
      new Promise((resolve, reject) => {
        setBackup(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Set backup daily failed.');
        }),
    );
  };
  /*
  const setAutomationOption = (data) => {
    // console.warn('setAutomationChecked', data);
    console.log('new Promise: UserSettings');
    trackPromise(
      new Promise((resolve, reject) => {
        setAutomation(data, resolve, reject);
      })
        .catch(() => {
          setMessage('Set automation failed.');
        }),
    );
  };
  */
  return (
    <Stack spacing={2}>
      <Box width={500} m={1}>
        {promiseInProgress && <Spinner text='Saving settings...' />}
        <Typography>
          Settings for
          {' '}
          {loginState.account_first_name}
          {' '}
          &lt;
          {loginState.account_email}
          &gt;
        </Typography>
        <FormGroup>
          <Box>
            <FormControlLabel
              label='Daily backups'
              control={
                (
                  <Checkbox
                    checked={settings.backups}
                    onChange={(event) => setBackupsChecked(event.target.checked)}
                  />
                )
}
            />
          </Box>
          {/*
        <FormControl fullWidth={true}>
          <InputLabel id='demo-simple-select-label'>Automatically add until</InputLabel>
          <Select
            // labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={settings.automation}
            label='Automatically add until'
            onChange={event => setAutomationOption(event.target.value)}
          >
            <MenuItem value={false}>Off</MenuItem>
            <MenuItem value='Today'>Today</MenuItem>
            <MenuItem value='Monday'>Monday</MenuItem>
            <MenuItem value='Tuesday'>Tuesday</MenuItem>
            <MenuItem value='Wednesday'>Wednesday</MenuItem>
            <MenuItem value='Thursday'>Thursday</MenuItem>
            <MenuItem value='Friday'>Friday</MenuItem>
            <MenuItem value='Saturday'>Saturday</MenuItem>
            <MenuItem value='Sunday'>Sunday</MenuItem>
          </Select>
        </FormControl>
        */}
        </FormGroup>
        <br />
        <form onSubmit={handleUpdatePassword}>
          <Box>
            <TextField
              name='password'
              label='Current Password'
              // placeholder='Current password'
              onChange={(e) => setPassword(e.target.value)}
              type='password'
              value={password}
            />
          </Box>
          <Box>
            <TextField
              name='newPassword'
              label='New Password'
              // placeholder='New password'
              onChange={(e) => setNewPassword(e.target.value)}
              type='password'
              value={newPassword}
            />
          </Box>
          <Box>
            <TextField
              name='newPassword2'
              label='New Password Again'
              // placeholder='New password'
              onChange={(e) => setNewPassword2(e.target.value)}
              type='password'
              value={newPassword2}
            />
          </Box>
          <Box>
            <Typography color='secondary'>
              {message}
            </Typography>
          </Box>
          <br />
          <Box>
            <Button
              type='submit'
              color='primary'
              variant='contained'
            >
              Update Password
            </Button>
          </Box>
        </form>
        <br />
        <form onSubmit={handleDeleteAccount}>
          <Box>
            <Button
              type='submit'
              color='error'
              variant='contained'
            >
              Delete Account
            </Button>
          </Box>
        </form>
      </Box>
    </Stack>
  );
}

export default UserSettings;
