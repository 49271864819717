// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import {
  Box,
} from '@material-ui/core';

import UserSettings from '../components/UserSettings';

function UserSettingsScreen() {
  return (
    <Box
      align='center'
    >
      <h2>User Settings</h2>
      <Box
        align='center'
      >
        Here is where you can control user settings like resetting your password.
        <UserSettings />
      </Box>
    </Box>
  );
}

export default UserSettingsScreen;
