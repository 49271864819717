// (C) Copyright 2020 MediaWink, LLC

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ReactFileReader from 'react-file-reader';
import {
  Button,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

import Spinner from './spinner/spinner';
import { triggerMergeSaga } from '../actions/imports';
import migrate from '../utils/migrate';
import { getNowString } from '../utils/time';

function FileMerge() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();
  const currentStateJson = useSelector((state) => state.undo.present.private);
  const dispatch = useDispatch();
  const mergeSaga = (data, resolve, reject, nav) => dispatch(
    triggerMergeSaga(data, resolve, reject, nav),
  );
  return (
    <>
      {promiseInProgress && <Spinner text='Merging in data from file...' />}
      <ReactFileReader
        handleFiles={
                (files) => {
                  const fileReader = new FileReader();
                  fileReader.onload = () => {
                    let p;
                    // console.log('new Promise: FileMerge');
                    trackPromise(
                      p = new Promise((resolve, reject) => {
                        const contentFromFile = fileReader.result;
                        const jsonFromFile = JSON.stringify(migrate(JSON.parse(contentFromFile)));
                        const data = {
                          fromFile: jsonFromFile,
                          currentState: currentStateJson,
                        };
                        mergeSaga(data, resolve, reject, navigate);
                      }),
                    );
                    // p.catch((e) => {
                    p.catch(() => {
                      // setHideError(true);
                      // console.log(e);
                    });
                  };
                  fileReader.readAsText(files[0]);
                }
            }
        fileTypes={['.ah']}
      >
        <Button
          color='primary'
          onClick={() => {
            // console.log('export backup');
            const blob = new Blob(
              [JSON.stringify(currentStateJson)],
              { type: 'text/json;charset=utf-8' },
            );
            console.warn('Download Just Before Merge!');
            saveAs(blob, `${getNowString()}_just_before_merge_achieveHub.ah`);
          }}
          variant='contained'
        >
          Merge
        </Button>
      </ReactFileReader>
    </>
  );
}

export default FileMerge;
