// (C) Copyright 2020 MediaWink, LLC

import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import {
  CLEAR_COOKIE_SAGA,
  CREATE_ACCOUNT_SAGA,
  COMPRESS_DATABASE_SAGA,
  CONNECT_SAGA,
  IMPORT_JSON_PRIVATE,
  IMPORT_JSON_PUBLIC,
  INSERT_JSON_PRIVATE,
  INSERT_JSON_PUBLIC,
  INJECT_ITEMS_INTO_REDUX_SAGA,
  LOGIN_SAGA,
  LOGOUT_SAGA,
  RESET_PASSWORD_SAGA,
  DELETE_ACCOUNT_SAGA,
  SET_AUTOMATION_SETTING_SAGA,
  SET_BACKUP_SETTING_SAGA,
  SET_ALLOWLIST_SETTING_SAGA,
  SET_SETTING_SAGA,
  SYNC_FROM_DATABASE_SAGA,
  SYNC_ITEMS_TO_DATABASE_SAGA,
  SWITCH_USER_SAGA,
} from '../action-types';
import achievehubAPI from '../apis/achievehubAPI';
import {
  selectPrivateBreadcrumbs,
  selectLastmodtime,
  selectLogin,
  selectLoginCookieString,
  selectAllModifiedItems,
  selectSelectedId,
  selectSettings,
} from '../selectors';
import defaultData from '../default';
import {
  clearDirtyBitForItem,
  setPrivateBreadcrumbs,
  setPublicBreadcrumbs,
  setSettings,
} from '../actions/items';
import {
  DEFAULT_BREADCRUMBS,
  DEFAULT_SETTINGS,
} from '../constants';

const ONE_YEAR_IN_SECONDS = 31536000;

function* parseObj(dbItemsObj, login, privateOrPublicData) {
  // console.log('parseObj data', dbItemsObj, login, privateOrPublicData);
  const { lastmodtime } = dbItemsObj;
  const jsonItems = privateOrPublicData.items.map((i) => {
    try {
      return JSON.parse(i);
    } catch (err) {
      console.warn('Failed to read JSON: [', i, ']', err);
    }
    return '';
  });

  const jsonObjs = jsonItems.reduce((obj, item) => ({
    ...obj,
    [item.id]: item,
  }), {});

  const tour = {};
  const selectedId = yield select(selectSelectedId);

  // Read breadcrumbs, etc. from DB
  let addStr = privateOrPublicData?.accountdata;
  // or from Redux...
  if (!addStr) {
    addStr = '{}';
  }
  const add = JSON.parse(addStr);
  /*
  if (!add?.breadcrumbs) {
    add.breadcrumbs = yield select(selectPrivateBreadcrumbs);
  }
  if (!add?.breadcrumbs) {
    add.breadcrumbs = yield select(selectPrivateBreadcrumbs);
  }
  */
  const { breadcrumbs, settings, nextId } = add;

  const obj = {
    lastmodtime,
    login,
    visibilityFilter: 'SHOW_ALL',
    tour,
    selectedId,
    nextId,
    breadcrumbs,
    settings,
    version: 7,
    entities: {
      items: Object.keys(jsonObjs).length > 0 ? jsonObjs : { [login.item.id]: login.item },
    },
  };
  // console.log({ obj });
  return obj;
}

export function* injectItemsIntoReduxSaga(action) {
  const { dbItemsObj, data, insert } = action;
  // console.log('in injectItemsIntoReduxSaga: action:', action);
  const priv = !!dbItemsObj.data?.private;
  const pub = !!dbItemsObj.data?.public;
  // console.log('priv', priv);
  // console.log('pub', pub);
  try {
    if (!priv) {
      dbItemsObj.data = {};
      dbItemsObj.data.private = { ...dbItemsObj };
    }
    const privateObj = priv ? yield parseObj(dbItemsObj, data, dbItemsObj.data.private) : {};
    const publicObj = pub ? yield parseObj(dbItemsObj, data, dbItemsObj.data.public) : {};
    const sharedObj = null; // TODO
    const all = { private: privateObj, shared: sharedObj, public: publicObj };
    const allStr = JSON.stringify(all);
    // console.log({ all });
    if (insert) {
      if (priv) {
        yield put({ type: INSERT_JSON_PRIVATE, json: allStr });
      }
      if (pub) {
        yield put({ type: INSERT_JSON_PUBLIC, json: allStr });
      }
    } else {
      if (priv) {
        yield put({ type: IMPORT_JSON_PRIVATE, json: allStr });
      }
      if (pub) {
        yield put({ type: IMPORT_JSON_PUBLIC, json: allStr });
      }
    }
  } catch (err) {
    console.warn(err);
  }
}

export function* clearCookieSaga(action) {
  // console.log('clearCookiesSaga', action);
  const { reject } = action;
  try {
    const cookies = new Cookies();
    cookies.remove('achievehub.com_login');
  } catch (e) {
    if (reject) {
      reject();
    }
  } finally {
    yield put({ type: 'LOGOUT', logout: action.data });
  }
}

// TODO: Get new PUBLIC items as well as private.
export function* syncFromDatabaseSaga(action) {
  console.log('syncFromDatabaseSaga', action);
  const {
    data, payload, resolve, reject, item, privateItem, publicItem, insert, recursive, navigate,
  } = action;
  try {
    console.log('get_items:');
    const itemsToGet = {
      ...data,
      item,
      recursive,
      privateItem: !privateItem || privateItem < 1 ? 1 : privateItem,
      publicItem: !publicItem || publicItem < 1 ? 1 : publicItem,
    };
    // console.log({ itemsToGet });
    let dbItemsObj = yield call(achievehubAPI.do, 'get_items', itemsToGet);
    // console.log('got items', dbItemsObj);
    // Read breadcrumbs from DB
    let addStr;
    let add;
    try {
      addStr = dbItemsObj.data.private.accountdata;
      add = JSON.parse(addStr);
    } catch (e) {
      console.log('syncFromDatabaseSaga caught', e);
      // Corrupt data--switch to defaults
      add = {
        breadcrumbs: DEFAULT_BREADCRUMBS,
        settings: DEFAULT_SETTINGS,
      };
    }
    let { settings } = add;

    // Push local items if none in DB
    if (dbItemsObj.data.private.items.length < 1) {
      console.log('NOTHING in DB!  Push to DB!');
      // Push json to DB...
      // For each item:
      yield call(achievehubAPI.do, 'add_items', {
        ...action.data,
        items: defaultData.entities.items,
      });
      // console.log('Then import it into Redux store...');
      dbItemsObj = yield call(achievehubAPI.do, 'get_items', {
        ...data,
        item,
        privateItem,
        publicItem,
      });
      console.log({ dbItemsObj });
    }

    // console.log('injectItemsIntoReduxSaga', dbItemsObj);
    yield injectItemsIntoReduxSaga(
      { ...action, dbItemsObj, type: INJECT_ITEMS_INTO_REDUX_SAGA },
    );

    const cookies = new Cookies();
    // console.log({ cookies });
    // console.log({ payload });
    if (payload) {
      const update = (data.keepSignedIn) !== (payload.keepSignedIn);
      const login = {
        ...data,
        keepSignedIn: payload.keepSignedIn || false,
      };

      // check to avoid infinite loop
      console.log('check to avoid infinite loop', update);
      if (update) {
        yield put({ type: 'LOGIN', login });
      }

      const cookie = yield select(selectLoginCookieString);
      cookies.set('achievehub.com_login', cookie, { maxAge: ONE_YEAR_IN_SECONDS });
    }
    // console.log({ breadcrumbs });
    /*
    if (!breadcrumbs || breadcrumbs.length === 0) {
      breadcrumbs = DEFAULT_BREADCRUMBS;
    }
    */
    // console.log({ settings });
    yield put(setSettings(settings));

    // console.log({ resolve });
    if (resolve) {
      console.log('call resolve()');
      resolve();
    }
    // console.log({ insert });
    if (!insert) {
      // TEMP: ALWAYS go home (for now?)
      yield put(setPrivateBreadcrumbs(DEFAULT_BREADCRUMBS));
      yield put(setPublicBreadcrumbs(DEFAULT_BREADCRUMBS));

      if (!settings) {
        settings = DEFAULT_SETTINGS;
      }
      console.log('navigate(/)');
      navigate('/');
    }
  } catch (e) {
    console.log('syncFromDatabaseSaga catch', e);
    // Set loginState.isLoggedIn = false; keepLoggedIn = false;
    // Clear cookies, if any
    console.log('call clearCookiesSaga');
    yield clearCookieSaga( // logs out?
      { ...action, type: CLEAR_COOKIE_SAGA },
    );
    // TODO: Also clear in memory, OR force a refresh?
    yield put({ type: 'LOGOUT', logout: action.data.account_email });
    if (reject) {
      console.log('call reject()');
      reject();
    }
    console.log('throw', e);
    throw (e);
  }
}

export function* compressDatabaseSaga(action) {
  const { data, resolve, reject } = action;
  // console.log('compressDatabaseSaga', action);
  try {
    const lastmodtime = yield select(selectLastmodtime);
    yield call(achievehubAPI.do, 'compress_database', {
      ...data.loginState,
      lastmodtime,
    });

    const { loginState } = data;
    // console.log('compressDatabaseSaga', action);
    yield syncFromDatabaseSaga({
      ...action,
      data: loginState,
      item: 1,
      privateItem: 1,
      publicItem: 1,
      type: SYNC_FROM_DATABASE_SAGA,
    });

    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

// For each item marked as dirty, update it in the DB
export function* updateDirtyItemsToDatabaseSaga() {
  const login = yield select(selectLogin);
  const modifiedItems = yield select(selectAllModifiedItems);
  const keys = Object.keys(modifiedItems);
  console.log('update dirty', modifiedItems);
  for (let i = 0; i < keys.length; i += 1) {
    const modifiedItem = modifiedItems[keys[i]];
    const payload = {
      ...login,
      item_id: modifiedItem.id,
      item: {
        ...modifiedItem,
        dirty: false,
      },
    };
    yield call(achievehubAPI.do, 'update_item', payload);
    yield put(clearDirtyBitForItem({ ...payload }));
  }
}

export function* switchUserSaga(action) {
  // Redux: Point login to new user account info

  const login = action.data;
  yield put({ type: 'LOGIN', login });

  const cookies = new Cookies();
  const cookie = yield select(selectLoginCookieString);
  cookies.set('achievehub.com_login', cookie, { maxAge: ONE_YEAR_IN_SECONDS });

  // Redux: Read data from DB
  // console.log('switchUserSaga', action);
  yield syncFromDatabaseSaga({
    ...action,
    item: 1,
    privateItem: 1,
    publicItem: 1,
    type: SYNC_FROM_DATABASE_SAGA,
  });
}
export function* connectSaga(action) {
  const { payload } = action;
  const response = yield call(achievehubAPI.do, 'login', payload);
  response.isLoggedIn = true;
  return response;
}

export function* loginSaga(action) {
  console.log('loginSaga', action);
  const { reject, navigate } = action;
  try {
    const response = yield connectSaga({ ...action, item: 1, type: CONNECT_SAGA });
    console.log('response', response);
    yield syncFromDatabaseSaga({
      ...action,
      /*
      data: {
        item: 1,
        privateItem: 1,
        publicItem: 1,
      },
      */
      ...response,
      type: SYNC_FROM_DATABASE_SAGA,
      item: 1,
      privateItem: 1,
      publicItem: 1,
    });
    console.log('done syncing from db');
    navigate('/');
  } catch (e) {
    console.warn('caught', e);
    if (reject) {
      reject();
    }
    action.err = e;
  }
}

export function* logoutSaga(action) {
  // console.log('logoutSaga', action);
  const { payload, resolve, navigate } = action;

  const login = yield select(selectLogin);
  // console.log('selected login', login);
  const logoutPayload = {
    ...login,
  };

  try {
    // console.log('logoutPayload', logoutPayload);
    yield call(achievehubAPI.do, 'logout', logoutPayload);
  } catch (e) {
    console.warn('e', e);
    // OK if we get an error--could already be logged out
  }
  try {
    // console.log('payload', payload);
    yield put({ type: 'LOGOUT', logout: payload.account_email });
    const newAccount = yield select(selectLogin);
    // console.log('newAccount', newAccount);
    yield syncFromDatabaseSaga({
      ...action,
      data: newAccount,
      item: 1,
      privateItem: 1,
      publicItem: 1,
      type: SYNC_FROM_DATABASE_SAGA,
    });
    const cookies = new Cookies();
    const cookie = JSON.stringify(newAccount);
    cookies.set('achievehub.com_login', cookie, { maxAge: ONE_YEAR_IN_SECONDS });
  } catch (e) {
    console.warn('error', e);
    if (resolve) {
      resolve();
    }
  } finally {
    // console.log('finally resolve');
    if (resolve) {
      resolve();
    }
    // console.log('navigate to /');
    navigate('/');
  }
}

export function* createAccountSaga(action) {
  const { data, resolve, reject } = action;
  try {
    const login = yield select(selectLogin);
    const createAccountPayload = {
      ...login,
      account: data,
      ...data,
    };
    const response = yield call(achievehubAPI.do, 'post_account', createAccountPayload);
    // response.isLoggedIn = true;
    if (resolve) {
      resolve();
    }
    return response;
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
  return false;
}

export function* resetPasswordSaga(action) {
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    const login = yield select(selectLogin);
    const resetPayload = {
      ...login,
      ...data,
    };
    yield call(achievehubAPI.do, 'resetPassword', resetPayload);
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* deleteAccountSaga(action) {
  // console.log('deleteAccountSaga(action)', action);
  const {
    data, resolve, reject,
  } = action;
  try {
    const login = yield select(selectLogin);
    const payload = {
      ...login,
      ...data,
    };
    // console.log(' delete_account', payload);
    yield call(achievehubAPI.do, 'delete_account', payload);
    yield logoutSaga(
      {
        ...action,
        type: LOGOUT_SAGA,
        payload: action,
      },
    );
    if (resolve) {
      // console.log(' resolve');
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* setAutomationSettingSaga(action) {
  console.warn('setAutomationSettingsSaga', action);
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    const settings = yield select(selectSettings);
    yield put(setSettings({ ...settings, 'automation': data }));
    const login = yield select(selectLogin);
    const breadcrumbs = yield select(selectPrivateBreadcrumbs);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings: {
          ...settings,
          'automation': data,
        },
      },
    };
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* setAllowlistSettingSaga(action) {
  console.warn('setAllowlistSettingSaga', action);
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    const settings = yield select(selectSettings);
    yield put(setSettings({ ...settings, 'allowlist': data }));
    const login = yield select(selectLogin);
    const breadcrumbs = yield select(selectPrivateBreadcrumbs);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings: {
          ...settings,
          'allowlist': data,
        },
      },
    };
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}
export function* setBackupSettingSaga(action) {
  console.warn('setBackupSettingSaga', action);
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    const settings = yield select(selectSettings);
    yield put(setSettings({ ...settings, 'backups': data }));
    const login = yield select(selectLogin);
    const breadcrumbs = yield select(selectPrivateBreadcrumbs);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings: {
          ...settings,
          'backups': data,
        },
      },
    };
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* setSettingSaga(action) {
  const {
    data, resolve, reject, navigate,
  } = action;
  try {
    yield put(setSettings(data));
    const login = yield select(selectLogin);
    const breadcrumbs = yield select(selectPrivateBreadcrumbs);
    const dataIn = {
      ...login,
      account_data: {
        breadcrumbs,
        settings: data,
      },
    };
    yield call(achievehubAPI.do, 'set_account_data', { ...dataIn });
    navigate('/');
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.warn('error', e);
    if (reject) {
      reject();
    }
  }
}

export function* watchSessionSagas() {
  yield takeLatest(CLEAR_COOKIE_SAGA, clearCookieSaga);
  yield takeLatest(CONNECT_SAGA, connectSaga);
  yield takeLatest(CREATE_ACCOUNT_SAGA, createAccountSaga);
  yield takeLatest(INJECT_ITEMS_INTO_REDUX_SAGA, injectItemsIntoReduxSaga);
  yield takeLatest(LOGIN_SAGA, loginSaga);
  yield takeLatest(LOGOUT_SAGA, logoutSaga);
  yield takeLatest(DELETE_ACCOUNT_SAGA, deleteAccountSaga);
  yield takeLatest(RESET_PASSWORD_SAGA, resetPasswordSaga);
  yield takeLatest(SET_AUTOMATION_SETTING_SAGA, setAutomationSettingSaga);
  yield takeLatest(SET_BACKUP_SETTING_SAGA, setBackupSettingSaga);
  yield takeLatest(SET_ALLOWLIST_SETTING_SAGA, setAllowlistSettingSaga);
  yield takeLatest(SET_SETTING_SAGA, setSettingSaga);
  yield takeLatest(COMPRESS_DATABASE_SAGA, compressDatabaseSaga);
  yield takeLatest(SYNC_FROM_DATABASE_SAGA, syncFromDatabaseSaga);
  yield takeLatest(SYNC_ITEMS_TO_DATABASE_SAGA, updateDirtyItemsToDatabaseSaga);
  yield takeLatest(SWITCH_USER_SAGA, switchUserSaga);
}
